/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card";
import { Seprator } from "../../components/Exotics/Seprator";
import LoaderGlobal from "../../components/Exotics/loaders/LoaderGlobal";
import Button from "../../components/buttons/Button";
import Column from "../../components/containers/Column";
import { CSCContainer } from "../../components/containers/ContentSpacingContainer/styles";
import Row from "../../components/containers/Row";
import EntityHeader from "../../components/headers/EntityHeader";
import GenericText from "../../components/texts/GenericText";
import useAuth from "../../hooks/useAuth";
import CustomerService from "../../services/api/customer/CustomerService";
import queryKeys from "../../services/utils/queryKeys";
import AccessBox from "./BoxClient/AccessBox";
import BasicInformation from "./BoxClient/BasicInformation";
import ExcelContainer from "./BoxClient/ExcelContainer";
import ImagesInformation from "./BoxClient/ImagesInformation";
import MkInformation from "./BoxClient/MkInformation";

const Client = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    navigate("/");
    return <></>;
  }

  const { profile } = useAuth();
  const initialState = { ...profile, companyId: Number(id) };

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.customer.details, initialState.companyId],
    queryFn: () => CustomerService.details({ id: initialState.companyId }),
  });

  const { mutate } = useMutation({
    mutationKey: [queryKeys.customer.exotic.updateImage],
    mutationFn: CustomerService.updateImages,
  });

  return (
    <>
      {data?.id && (
        <EntityHeader
          cnpj={data?.cnpj || ""}
          name={data?.name || ""}
          names={[]}
          id={data?.id}
          brand={data?.companiesImage.image || ""}
          status={data.active ? "active" : "inactive"}
          title=""
          sendChangeImage={(blob) => {
            const form = new FormData();
            form.append("file", blob);
            mutate({ form, id: initialState.companyId || "", type: "brand" });
          }}
        />
      )}
      <Row justifyContent="space-around">
        <Button
          width={"300px"}
          text="Cadastrar associados"
          onClick={() => navigate(`/associados/cadastro/${id}`)}
          height={"40px"}
        />
        <Row width={"300px"} />

        <Row width={"300px"} />
      </Row>
      <Seprator margin={"20px 0px"} />

      {data?.id && (
        <Row justifyContent="space-around">
          <Card
            title={String(data.companiesDatum.activeUsers)}
            subTitle="Associados ativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/associados/home/${id}/ativos`),
            }}
          />
          <Card
            title={String(data.companiesDatum.inactiveUsers)}
            subTitle="Associados inativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/associados/home/${id}/inativos`),
            }}
          />
          <Card
            title={String(data.companiesDatum.superAppUsers)}
            subTitle="Usuários App"
            backgroundColor="white"
            removeButton
          />
        </Row>
      )}
      <Seprator margin={"20px 0px"} />
      <Column>
        <GenericText
          size={"h4"}
          font="Bold"
          margin={"15px"}
          alignSelf="flex-start"
        >
          {` Dados da(o) ${data?.name}`}
        </GenericText>
        <CSCContainer>
          {data?.id && <BasicInformation basic={data} />}
          {data?.id && <ImagesInformation basic={data} />}
          {data?.id && <MkInformation basic={data} />}
          {data?.id && <ExcelContainer basic={data} />}
          {data?.id && <AccessBox basic={data} />}
        </CSCContainer>
      </Column>
      {isLoading && <LoaderGlobal />}
    </>
  );
};
export default Client;
