import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarWrapper = styled.div`
  background: #f2f2f2;
  width: 190px;
  height: 100vh;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-right: #d9d9d9 3px solid;
  overflow-y: auto;
`;

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoLink = styled(Link)`
  display: block;
  padding: 15px 5%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 70px;
`;

export const NavList = styled.div`
  width: 100%;
  padding: 0px 3px;
  margin: 8px 0;
  flex-grow: 1;
`;

export const SidebarCustom = styled.div`
  width: 100%;
  flex-shrink: 0;
  background-color: transparent;
  color: #d7d7d7;
`;
