import ActiveIcon from "../../../assets/Icons/ActiveIcon";
import DeleteIcon from "../../../assets/Icons/DeleteIcon";
import Padlock from "../../../assets/Icons/Padlock";
import Row from "../../../components/containers/Row";
import { IRow } from "../../../components/Table/interface/IRow";
import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";

export const homeEmployee = ({
  defaultProps = {},
  handleChangeModalPassword,
  handleChangeModalStatus,
}: {
  defaultProps?: IRow;
  handleChangeModalPassword: (row: any) => void;
  handleChangeModalStatus: (row: any) => void;
}) => {
  const { onClick = () => {} } = defaultProps;

  return [
    {
      name: "ID",
      cell: (row: any) => (
        <RowId {...defaultProps} onClick={() => onClick(row)}>
          {row.id}
        </RowId>
      ),
    },
    {
      name: "Email",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.email}
        </RowText>
      ),
    },
    {
      name: "Nome",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.name}
        </RowText>
      ),
    },
    {
      name: "Tipo",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.role === "admin" ? "interno" : "parceiro"}
        </RowText>
      ),
    },
    {
      name: "ID acesso",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.accessId}
        </RowText>
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <RowStatus
          {...defaultProps}
          status={row.active ? 1 : 0}
          onClick={() => onClick(row)}
        />
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <Row style={{ gap: "10px" }} {...defaultProps}>
          <Padlock onClick={() => handleChangeModalPassword(row)} />
          {row.active ? (
            <DeleteIcon onClick={() => handleChangeModalStatus(row)} />
          ) : (
            <ActiveIcon onClick={() => handleChangeModalStatus(row)} />
          )}
        </Row>
      ),
    },
  ];
};
