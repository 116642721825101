/* eslint-disable import/no-anonymous-default-export */
import CreateEmployee from "./CreateEmployee";
import GetEmployee from "./GetEmployee";
import UpdateEmployee from "./UpdateEmployee";

class service {
  getEmployees = GetEmployee;
  updateEmployee = UpdateEmployee;
  createEmployee = CreateEmployee;
}

export default new service();
