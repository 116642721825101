import EditIcon from "../../../assets/Icons/EditIcon";
import Row from "../../../components/containers/Row";
import { IRow } from "../../../components/Table/interface/IRow";
import RowId from "../../../components/Table/RowId";
import RowText from "../../../components/Table/RowText";

export const accessHome = ({
  defaultProps = {},
  navigateDetails,
}: {
  defaultProps?: IRow;
  navigateDetails: (row: any) => void;
}) => {
  const { onClick = () => {} } = defaultProps;

  return [
    {
      name: "ID",
      cell: (row: any) => (
        <RowId {...defaultProps} onClick={() => onClick(row)}>
          {row.id}
        </RowId>
      ),
    },
    {
      name: "Nome",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.name}
        </RowText>
      ),
    },
    {
      name: "Tipo",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.role === "admin" ? "interno" : "parceiro"}
        </RowText>
      ),
    },
    {
      name: "Total de permissões",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.permissions.length}
        </RowText>
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <Row
          style={{ gap: "10px" }}
          {...defaultProps}
          onClick={() => navigateDetails(row)}
        >
          <EditIcon />
        </Row>
      ),
    },
  ];
};
