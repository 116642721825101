import { useMutation, useQuery } from "@tanstack/react-query";
import RegistrationForm from "../../../../components/forms/RegistrationForm";
import queryKeys from "../../../../services/utils/queryKeys";
import CustomerService from "../../../../services/api/customer/CustomerService";
import { useState } from "react";
import useDebounce from "../../../../helpers/hooks/useDebounce";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import { CSCContainer } from "../../../../components/containers/ContentSpacingContainer/styles";
import Column from "../../../../components/containers/Column";
import GenericText from "../../../../components/texts/GenericText";
import Row from "../../../../components/containers/Row";
import DeleteIcon from "../../../../assets/Icons/DeleteIcon";
import SvaService from "../../../../services/api/sva/SvaService";
import { toast } from "react-toastify";
import LoaderGlobal from "../../../../components/Exotics/loaders/LoaderGlobal";

export default function SvaCreate() {
  const [companyName, setCompanyName] = useState("");
  const [company, setCompany] = useState<{ id: number; name: string }>();
  const [title, setTitle] = useState("");
  const [ruleInput, setRuleInput] = useState("");
  const [rules, setRules] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState<File>();
  const [url, setUrl] = useState("");
  const debounceInputName = useDebounce(companyName, 500);
  const { data: customers, isLoading: loadingCompanies } = useQuery({
    queryKey: [queryKeys.customer.company.list, debounceInputName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 30,
        page: 0,
        name: debounceInputName,
   
      }),
  });
  const { mutate , isPending } = useMutation({
    mutationKey: [queryKeys.sva.create],
    mutationFn: SvaService.create,
    onSuccess: () => {
      setCompany(undefined);
      setTitle("");
      setDescription("");
      setBrand(undefined);
      setRules([]);
      setUrl("");
      toast.success("Serviço cadastrado com sucesso! 👌");
    },
    onError: () => {
      toast.error("Erro ao cadastrar Serviço");
    },
  });

  function execute() {
    const formData = new FormData();
    formData.append("companyId", String(company?.id));
    formData.append("title", title);
    formData.append("description", description);
    formData.append("file", brand as Blob);
    formData.append("rules", JSON.stringify(rules));
    formData.append("type", "web");
    formData.append("href", url);

    mutate(formData);
  }
  return (
    <RegistrationForm
      title="Cadastrar Serviço de valor agregado"
      buttonProps={{
        onClick: () => execute(),
        disabled: !company || !title || !description || !brand || !rules.length,
      }}
      renderInsideComponents={() => (
        <Column>
          <CSCContainer>
            <Select
              onInputChange={(e) => setCompanyName(e)}
              label="Empresa"
              placeholder={"Escolha o cliente"}
              data={customers?.data || []}
              getOptionLabel={(e: any) => e.name}
              getOptionValue={(e: any) => e.id}
              onChange={(item: any) => {
                setCompany(item);
              }}
              value={company}
              required
              isLoading={loadingCompanies}
            />
            <Input
              value={ruleInput}
              onChange={({ target }) => setRuleInput(target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setRules((prev) => [...prev, ruleInput]);
                  setRuleInput("");
                }
              }}
              width={"100%"}
              label="Passo a passo"
              placeholder="(Precione enter para adicionar)"
              type="text"
              margin={"5px 0px"}
            />
            <Input
              name="title"
              label="Nome do SVA"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Digite o titulo para o sva"
              required
            />
            <Input
              name="url"
              label="Link de ação"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="link para direcionar o usuario"
              required
            />
            <Input
              name="description"
              label="Descrição do SVA"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descreva o SVA selecionado"
              required
            />
            <Input
              name="brand"
              type="file"
              label="Logo do sva"
              onChange={(e) => {
                if (!e.target?.files) return;
                setBrand(e.target.files[0]);
              }}
              placeholder="Logo do sva"
              required
            />
          </CSCContainer>
          <CSCContainer>
            {rules.map((rule, index) => (
              <Row justifyContent="flex-start">
                <DeleteIcon
                  onClick={() =>
                    setRules((state) => state.filter((_, i) => i !== index))
                  }
                  style={{ cursor: "pointer" }}
                />
                <GenericText
                  margin="0px 0px 0px 10px"
                  key={`rule-test-${rule}-${index}`}
                >
                  {rule}
                </GenericText>
              </Row>
            ))}
          </CSCContainer>
          {isPending && <LoaderGlobal/>}
        </Column>
      )}
    />
  );
}
