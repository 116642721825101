const queryKeys = {
  global: {
    state: {
      list: "global-states-list",
      details: "global-states-details",
      numbers: "global-states-numbers",
      update: "global-states-update",
      create: "global-states-create",
      delete: "global-states-delete",
    },
    city: {
      list: "global-cities-list",
      details: "global-cities-details",
      numbers: "global-cities-numbers",
      update: "global-cities-update",
      create: "global-cities-create",
      delete: "global-cities-delete",
    },
    exotic: {
      cnpj: "global-exotic-cnpj",
      cep: "global-exotic-cep",
    },
  },
  plans: {
    list: "plans-list",
    details: "plans-details",
    numbers: "plans-numbers",
    update: "plans-update",
    create: "plans-create",
    delete: "plans-delete",
  },
  products: {
    list: "products-list",
    details: "products-details",
    numbers: "products-numbers",
    update: "products-update",
    create: "products-create",
    delete: "products-delete",
    exotic: {
      search: "products-exotic-search",
    },
  },
  cinema: {
    brand: {
      list: "cinema-brand-list",
      details: "cinema-brand-details",
      numbers: "cinema-brand-numbers",
      update: "cinema-brand-update",
      create: "cinema-brand-create",
      delete: "cinema-brand-delete",
    },
    order: {
      list: "cinema-order-list",
      details: "cinema-order-details",
      numbers: "cinema-order-numbers",
      update: "cinema-order-update",
      create: "cinema-order-create",
      delete: "cinema-order-delete",
    },
    products: {
      list: "cinema-product-list",
      details: "cinema-product-details",
      numbers: "cinema-product-numbers",
      update: "cinema-product-update",
      create: "cinema-product-create",
      delete: "cinema-product-delete",
      exotic: {
        productType: "cinema-product-type",
      },
    },
    voucher: {
      list: "cinema-voucher-list",
      details: "cinema-voucher-details",
      numbers: "cinema-voucher-numbers",
      update: "cinema-voucher-update",
      create: "cinema-voucher-create",
      delete: "cinema-voucher-delete",
      exotic: {
        example: "cinema-voucher-example",
      },
    },
    images: {
      list: "cinema-images-list",
      details: "cinema-images-details",
      numbers: "cinema-images-numbers",
      update: "cinema-images-update",
      create: "cinema-images-create",
      delete: "cinema-images-delete",
    },
  },
  notifications: {
    list: "notifications-list",
    details: "notifications-details",
    numbers: "notifications-numbers",
    update: "notifications-update",
    create: "notifications-create",
    delete: "notifications-delete",
  },
  associate: {
    list: "associate-list",
    details: "associate-details",
    numbers: "associate-numbers",
    update: "associate-update",
    create: "associate-create",
    delete: "associate-delete",
    product: {
      list: "associate-product-list",
      details: "associate-product-details",
      numbers: "associate-product-numbers",
      update: "associate-product-update",
      create: "associate-product-create",
      delete: "associate-product-delete",
    },
  },
  users: {
    list: "user-list",
    details: "user-details",
    numbers: "user-numbers",
    update: "user-update",
    create: "user-create",
    delete: "user-delete",
  },
  customer: {
    list: "customer-list",
    details: "customer-details",
    numbers: "customer-numbers",
    update: "customer-update",
    create: "customer-create",
    delete: "customer-delete",
    company: {
      list: "customer-company-list",
      details: "customer-company-details",
      numbers: "customer-company-numbers",
      update: "customer-company-update",
      create: "customer-company-create",
    },
    segment: {
      list: "customer-segment-list",
      detaisl: "customer-segment-details",
      numbers: "customer-segment-numbers",
      update: "customer-segment-update",
      create: "customer-segment-create",
      delete: "customer-segment-delete",
    },
    product: {
      list: "customer-product-list",
      details: "customer-product-details",
      numbers: "customer-product-numbers",
      update: "customer-product-update",
      create: "customer-product-create",
      delete: "customer-product-delete",
    },
    exotic: {
      updateImage: "customer-update-image",
      updateStatus: "customer-update-status",
      listBanner: "customer-list-banner",
      updateBanner: "customer-update-banner",
      deleteBanner: "customer-delete-banner",
      listBannerIsp: "customer-list-banner-isp",
      createExcel: "customer-post-excel",
      updateFinancial: "customer-update-financial",
      listMnvoIntegration: "customer-list-mvno-integration",
      createMvno: "customer-create-mvno-integration",
      createAccess: "customer-create-access",
      listAccess: "customer-list-access",
    },
    mediquo: {
      details: "mediquo-details",
      createOrUpdate: "mediquo-createOrUpdate",
    },
  },
  integration: {
    list: "integration-list",
    details: "integration-details",
    numbers: "integration-numbers",
    update: "integration-update",
    create: "integration-create",
    delete: "integration-delete",
    snyc: {
      list: "integration-sync-list",
      details: "integration-sync-details",
      numbers: "integration-sync-numbers",
      update: "integration-sync-update",
      create: "integration-sync-create",
      delete: "integration-sync-delete",
    },
  },
  profile: {
    list: "profile-list",
    details: "profile-details",
    create: "profile-create",
    numbers: "profile-numbers",
    update: "profile-update",
  },
  module: {
    list: "module-list",
    details: "module-details",
    create: "module-create",
    numbers: "module-numbers",
    update: "module-update",
  },
  access: {
    list: "access-list",
    details: "access-details",
    create: "access-create",
    numbers: "access-numbers",
    update: "access-update",
  },
  employee: {
    list: "employee-list",
    details: "employee-details",
    create: "employee-create",
    numbers: "employee-numbers",
    update: "employee-update",
  },
  sva: {
    create: "create-sva",
  },
  financial: {
    list: "financial-list",
    details: "financial-details",
    create: "financial-create",
    numbers: "financial-numbers",
    update: "financial-update",
  },
};

export default queryKeys;
