import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
      style={{ stroke:   "#000" }}
    >
      <path
        stroke={  "#1B1B1B"}
        style={{ stroke: "#000" }}
        strokeLinecap="round"
        strokeWidth="2.36"
        d="M17.307 33.04H33.04"
      ></path>
      <path
        stroke={"#1B1B1B"}
        style={{ stroke: "#000" }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.36"
        d="M26.565 7.59C24.41 5.7 21.12 5.9 19.214 8.031c0 0-9.477 10.6-12.763 14.278-3.29 3.677-.879 8.757-.879 8.757s5.432 1.703 8.672-1.922l12.768-14.277a5.122 5.122 0 00-.447-7.279z"
        clipRule="evenodd"
      ></path>
      <path
        stroke={"#1B1B1B"}
        style={{ stroke: "#000" }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.36"
        d="M16.3 11.473l7.183 6.273"
      ></path>
    </svg>
  );
}

export default EditIcon;
