import { AxiosResponse } from "axios";
import queryFormatter from "../../../utils/queryFormatter";
import { axiosProvider } from "../../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../../interface/DefaultErrorResponse";


const createIOrUpdateMediquo = async ({
  id,
  update,
}: {
  id: number | string;
  update: { apiKey: string; secretKey: string };
}): Promise<void> => {
  const URL = queryFormatter({
    route: `telemedicine/dashboard/company/${id}`,
  });

  try {
    const { data }: AxiosResponse<null> = await axiosProvider.put(URL, update);
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default createIOrUpdateMediquo;
