import { useQuery } from "@tanstack/react-query";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import Column from "../../../../../../components/containers/Column";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import GenericText from "../../../../../../components/texts/GenericText";
import { YesOrNot } from "../../../../../../mocks/YesOrNot";
import UtilsService from "../../../../../../services/api/utils/UtilsService";
import queryKeys from "../../../../../../services/utils/queryKeys";

export default function ThirdStepPush(props: {
  push: Record<string, any>;
  changePush: (e: any) => void;
}) {
  const theme = useTheme();
  const isCepValid = String(props?.push?.cep).replace("-", "").length === 8;
  const { data, isSuccess } = useQuery({
    queryKey: [queryKeys.global.exotic.cep, props?.push?.cep],
    queryFn: () =>
      UtilsService.geolocInformationByCep(
        String(props?.push?.cep).replace("-", "")
      ),
    enabled: isCepValid,
  });
  function onChangeRange(v: number | number[]) {
    props.changePush((el: any) => ({
      ...el,
      geolocation: {
        ...el.geolocation,
        range: v,
      },
    }));
  }
  function onChangeCepRender(e: any) {
    props.changePush((el: any) => ({
      ...el,
      renderCep: e.value,
    }));
  }
  function onChangeCep({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    props.changePush((el: any) => ({
      ...el,
      cep: target.value,
    }));
  }
  useEffect(() => {
    if (isSuccess && data?.results) {
      if (!data.results[0]) return;

      props.changePush((el: any) => ({
        ...el,
        geolocation: {
          ...el.geolocation,
          latitude: data.results[0]?.geometry?.location?.lat.toString(),
          longitude: data.results[0]?.geometry?.location?.lng.toString(),
        },
      }));
    }
  }, [isSuccess, data]);

  return (
    <>
      <Select
        label="Push Localizado?"
        placeholder={"O push será localizado?"}
        data={YesOrNot}
        value={YesOrNot.find((el) => el.value === props?.push?.renderCep)}
        getOptionLabel={(e: any) => e.label}
        getOptionValue={(e: any) => e.value}
        onChange={onChangeCepRender}
        required
      />
      {props?.push?.renderCep && (
        <>
          <Input
            name="cep"
            label="Cep"
            maskType="cep"
            value={props?.push?.cep}
            onChange={onChangeCep}
            placeholder="Digite o titulo para a notificação"
            required
          />
          <Column alignItems="flex-start" alignSelf="center">
            <GenericText margin={"10px 0px"}>
              Distancia* - {props.push?.geolocation?.range}Km
            </GenericText>
            <Slider
              value={props?.push?.geolocation?.range || 1}
              onChange={onChangeRange}
              min={1}
              max={50}
              styles={{
                track: { backgroundColor: theme.primary },
                handle: { backgroundColor: theme.primary },
              }}
            />
          </Column>
        </>
      )}
    </>
  );
}
