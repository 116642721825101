/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SubmitEditsForm from "../../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import { formatDate } from "../../../../../../helpers/date/formatDate";
import { removeMaskDate } from "../../../../../../helpers/date/removeMaskDate";
import { currencyMask } from "../../../../../../helpers/mask/currencyMask";
import convertEmptyStringsToNullOrDelete from "../../../../../../helpers/object/convertEmptyStringsToNull ";
import changeStateByInputName from "../../../../../../helpers/state/changeStateByInputName";
import useVerifyObjectEqual from "../../../../../../hooks/useVerifyObjectEqual";
import { IPlan } from "../../../../../../services/api/Plans/interface/IPlans";
import PlansService from "../../../../../../services/api/Plans/PlansService";
import queryKeys from "../../../../../../services/utils/queryKeys";

const BasicInformation = ({ basic }: { basic: IPlan }) => {
  const [details, setDetails] = useState<Record<string, any>>({});
  const [value, setValue] = useState("");
  const [initialObject, setInitialObject] = useState({});

  const disabledButton = useVerifyObjectEqual({
    initialObject,
    actualObject: details,
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.plans.update],
    mutationFn: PlansService.updatePlan,
  });

  const init = () => {
    const object = {
      ...basic,
      value: basic.value.toString().replace(".", ","),
    };

    setDetails(object);
    setInitialObject(object);
    setValue(`R$ ${basic.value.toString().replace(".", ",")}`);
  };

  const handleConfirm = () => {
    const { companyId, information, ...rest } = details;
    const body = {
      ...rest,
      limitDate: removeMaskDate(rest?.limitDate),
      value: Number(parseFloat(details.value.replace(",", ".")).toFixed(2)),
    };

    const formatBody = convertEmptyStringsToNullOrDelete(body, true);
    mutate(formatBody as any);
    setInitialObject(details);
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    setDetails((prev) => ({ ...prev, value: value.replace("R$ ", "") }));
  }, [value]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Plano atualizado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <SubmitEditsForm
        title="Informaçoes"
        buttonProps={{
          disabled: disabledButton,
          onClick: handleConfirm,
          loading: isPending,
        }}
        renderInsideComponents={() => (
          <>
            <Input
              value={details?.title}
              width={"100%"}
              name="title"
              placeholder="Nomde do plano"
              label="Nome"
              type="text"
              margin={"5px 0px"}
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />
            <Input
              name="description"
              value={details?.description}
              width={"100%"}
              label="Descrição"
              placeholder="Descrição do plano"
              type="textArea"
              margin={"5px 0px"}
              rows={4}
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />
            <Input
              name="rules"
              value={details?.rules}
              width={"100%"}
              label="Regras"
              placeholder="Regras do plano"
              type="textArea"
              margin={"5px 0px"}
              rows={4}
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />
            <Select
              label="Frequencia de pagamento"
              value={
                details?.paymentFrequency === "month"
                  ? { label: "Mensal", id: "month" }
                  : { label: "Anual", id: "year" }
              }
              data={[
                {
                  label: "Mensal",
                  id: "month",
                },
                {
                  label: "Anual",
                  id: "year",
                },
              ]}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.id}
              onChange={(e: any) =>
                setDetails((prev) => ({ ...prev, paymentFrequency: e.id }))
              }
            />
            <Input
              name="limitDate"
              value={formatDate(details.limitDate)}
              width={"100%"}
              label="Data limite"
              type="text"
              placeholder="Data limite do plano"
              margin={"5px 0px"}
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />
            <Input
              name="value"
              value={value}
              width={"100%"}
              placeholder="R$ 00,00"
              label="Valor"
              type="text"
              margin={"5px 0px"}
              onChange={({ target }) => currencyMask({ target, setValue })}
            />
          </>
        )}
      />
    </>
  );
};

export default BasicInformation;
