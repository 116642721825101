/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReactNode, useCallback, useMemo, useState } from "react";
import ICities from "../services/api/utils/interfaces/ICities";
import UtilsService from "../services/api/utils/UtilsService";
import queryKeys from "../services/utils/queryKeys";
import { ILocationContext } from "./ILocationContext";
import LocationContext from "./LocationContext";

const LocationProvider = ({ children }: { children: ReactNode }) => {
  const [citiesObject, setCitiesObject] = useState<Record<string, ICities>>({});

  const { data: states, isLoading: isLoadingStates } = useQuery({
    queryKey: [queryKeys.global.state.list],
    queryFn: () => UtilsService.getStates(),
  });

  const { mutate: mutationCities } = useMutation({
    mutationKey: [queryKeys.global.city.list],
    mutationFn: (id: string) => UtilsService.getCities(id),
    onSuccess: (data: ICities[], variables: string) => {
      setCitiesObject((prev) => ({ ...prev, [variables as any]: data }));
    },
  });

  const getCities = useCallback(
    (stateId: any) => {
      mutationCities(stateId);
    },
    [mutationCities]
  );
  const value: ILocationContext = useMemo(
    () => ({
      states,
      cities: citiesObject,
      isLoadingStates,
      getCities,
    }),
    [isLoadingStates, states, citiesObject]
  );

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
