/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/LogoIcon";
import { IPage } from "../../services/api/profile/interface/IProfile";
import ProfileService from "../../services/api/profile/ProfileService";
import queryKeys from "../../services/utils/queryKeys";
import LoaderGlobal from "../Exotics/loaders/LoaderGlobal";
import SidebarItem from "./SlideBarItem";
import {
  LogoLink,
  NavbarWrapper,
  NavList,
  SidebarCustom,
  Wrap,
} from "./styles";

const Navbar = () => {
  const [pages, setPage] = useState<IPage[]>([]);

  const isDev = process.env.REACT_APP_DEV === "true";
  const devUrl = process.env.REACT_APP_URL;
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.profile.details],
    queryFn: ProfileService.getProfile,
  });
  const initPages = () => {
    if (data) {
      const pages = data.pages;

      if (isDev && devUrl) {
        const updatedPages = pages.map((route) => {
          // Atualizar o slug principal
          const updatedSlug = route.slug.includes(
            "https://starbase.stations.cloud/"
          )
            ? route.slug.replace(
                "https://starbase.stations.cloud/",
                `${devUrl}/`
              )
            : route.slug;

          // Atualizar os slugs dos filhos
          const updatedChildrens = route.childrens.map((child) => ({
            ...child,
            slug: child.slug.includes("https://starbase.stations.cloud/")
              ? child.slug.replace(
                  "https://starbase.stations.cloud/",
                  `${devUrl}/`
                )
              : child.slug,
          }));

          // Retornar a rota atualizada
          return {
            ...route,
            slug: updatedSlug,
            childrens: updatedChildrens,
          };
        });

        setPage(updatedPages);
        return;
      }

      // Caso não seja dev mode, mantém as páginas como estão
      setPage(pages);
    }
  };

  useEffect(() => {
    initPages();
  }, [data, devUrl, isDev]);

  return (
    <>
      <NavbarWrapper>
        <Wrap>
          <LogoLink to="/">
            <LogoIcon width={150} height={50} />
          </LogoLink>
          <NavList>
            <SidebarCustom>
              {pages.map((page, i) => (
                <SidebarItem
                  item={page}
                  key={`items-side-bar-${page.title}-${i}`}
                />
              ))}
            </SidebarCustom>
          </NavList>
        </Wrap>
      </NavbarWrapper>
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default Navbar;
