/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IFinancial } from "./interface";

const CreateAndUpateFinancial = async (
  id: number,
  body: Omit<IFinancial, "id">
): Promise<void> => {
  const URL = queryFormatter({
    route: `financial/dashboard/company/${id}`,
  });

  try {
    const { data }: AxiosResponse<void> = await axiosProvider.put(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default CreateAndUpateFinancial;
