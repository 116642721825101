/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IGetModules } from "./interface/IModule";

const GetModules = async (): Promise<IGetModules> => {
  const URL = queryFormatter({
    route: `dashboard/module`,
  });
  try {
    const { data }: AxiosResponse<IGetModules> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetModules;
