/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import Row from "../../../components/containers/Row";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import RegistrationForm from "../../../components/forms/RegistrationForm";
import { CSCContainer } from "../../../components/forms/RegistrationForm/styles";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import AccessService from "../../../services/api/access/AccessService";
import CustomerService from "../../../services/api/customer/CustomerService";
import moduleService from "../../../services/api/modules/moduleService";
import queryKeys from "../../../services/utils/queryKeys";

const AccessRegistration = () => {
  const defaultPermissions = [
    { id: 1, name: "leitura", defaultName: "read" },
    { id: 2, name: "criar", defaultName: "create" },
    { id: 3, name: "atualizar", defaultName: "update" },
    { id: 4, name: "excluir", defaultName: "delete" },
  ];
  const [companyName, setCompanyName] = useState("");

  const [createAccess, setCreateAccess] = useState<Record<string, any>>({
    role: "admin",
    permissions: [],
  });

  const { data: modules, isLoading } = useQuery({
    queryKey: [queryKeys.module.list],
    queryFn: moduleService.getModule,
  });

  const { mutate } = useMutation({
    mutationKey: [queryKeys.access.create],
    mutationFn: AccessService.createAccess,
    onSuccess: () => {
      toast.success("Cargo criado com sucesso");
      setCreateAccess({
        role: "admin",
        permissions: [],
      });
    },
  });

  const { data: companies, isLoading: isLoadingCompanies } = useQuery({
    queryKey: [queryKeys.customer.company.list, companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });

  let timeoutRef: any;
  const handleCompanyName = (value: string) => {
    clearTimeout(timeoutRef);

    timeoutRef = setTimeout(() => {
      setCompanyName(value);
    }, 500);
  };

  const handleChangePermission = (
    selectedOptions: any[],
    moduleId: number,
    prevPermissions: any[],
    defaultPermissions: { id: number; name: string; defaultName: string }[]
  ) => {
    // Inicia todas as permissões como false
    const newPermissions = defaultPermissions.reduce((acc: any, perm) => {
      acc[perm.defaultName] = false;
      return acc;
    }, {});

    // Define as permissões selecionadas como true
    selectedOptions.forEach((item: any) => {
      newPermissions[item.defaultName] = true;
    });

    // logica para excluir o módulo se todas as permissões estiverem desativadas
    // Verifica se todas as permissões estão desativadas (false)
    const hasAnyPermission = Object.values(newPermissions).some(
      (value) => value
    );

    if (!hasAnyPermission) {
      // Remove o módulo se todas as permissões estiverem desativadas (false)
      return prevPermissions.filter((p: any) => p.moduleId !== moduleId);
    }

    // logica para adicionar ou atualizar o módulo
    // Verifica se o módulo já existe
    const existingModule = prevPermissions.find(
      (p: any) => p.moduleId === moduleId
    );

    if (existingModule) {
      // Atualiza as permissões do módulo existente
      existingModule.permissions = newPermissions;
      return [...prevPermissions];
    }

    // Adiciona um novo módulo se não existir
    return [...prevPermissions, { moduleId, permissions: newPermissions }];
  };

  return (
    <>
      <RegistrationForm
        title="Cadastro de cargos"
        buttonProps={{
          onClick: () => mutate(createAccess as any),
          disabled: !createAccess?.name || !createAccess?.companyId,
        }}
        renderHeaderComponent={() => (
          <Row justifyContent="space-between" width={"55%"}>
            <Input
              onChange={(e) => changeStateByInputName(e, setCreateAccess)}
              name="name"
              value={createAccess?.name || ""}
              width={"300px"}
              label="Nome"
              type="text"
              required
              placeholder="Nome"
            />
            <Select
              data={companies?.data || []}
              required
              getOptionLabel={(option: any) => option?.name}
              getOptionValue={(option: any) => option?.id}
              isLoading={isLoadingCompanies}
              onInputChange={(e) => {
                handleCompanyName(e);
              }}
              onChange={(e: any) => {
                setCreateAccess((prev) => ({
                  ...prev,
                  companyId: e?.id,
                }));
              }}
              value={companies?.data?.find(
                (company: any) => company.id === createAccess?.companyId
              )}
              placeholder="Empresa"
              label="Empresa"
              width={"300px"}
            />
          </Row>
        )}
        renderInsideComponents={() => (
          <CSCContainer>
            {modules?.data
              .filter((item) => item.isDefault !== true)
              .map((item, index) => (
                <Row
                  style={{ marginBottom: "4px" }}
                  key={`modules-access-registration-${index}`}
                >
                  <Select
                    data={defaultPermissions}
                    getOptionLabel={(option: any) => option?.name}
                    getOptionValue={(option: any) => option?.id}
                    onChange={(e: any) => {
                      setCreateAccess((prev) => ({
                        ...prev,
                        permissions: handleChangePermission(
                          e,
                          item.id,
                          prev.permissions,
                          defaultPermissions
                        ),
                      }));
                    }}
                    value={defaultPermissions.filter(
                      (permission) =>
                        createAccess.permissions.find(
                          (p: any) => p.moduleId === item.id
                        )?.permissions?.[permission.defaultName]
                    )}
                    label={item.name}
                    placeholder={`Selecione as permissões do módulo ${item.name}`}
                    isMulti
                  />
                </Row>
              ))}
          </CSCContainer>
        )}
      />
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default AccessRegistration;
