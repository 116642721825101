import { AxiosResponse } from "axios";
import queryFormatter from "../../../utils/queryFormatter";
import { axiosProvider } from "../../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../../interface/DefaultErrorResponse";

const getMediquoByid = async (
  id: number | string
): Promise<{ apiKey: string; secretKey: string }> => {
  const URL = queryFormatter({
    route: `telemedicine/dashboard/company/${id}`,
  });

  try {
    const { data }: AxiosResponse<{ apiKey: string; secretKey: string }> =
      await axiosProvider.get(URL);
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default getMediquoByid;
