import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import Box from "../../components/containers/Box";
import Column from "../../components/containers/Column";
import Row from "../../components/containers/Row";
import { Seprator } from "../../components/Exotics/Seprator";
import Input from "../../components/Input";
import BoxSelect from "../../components/Select/BoxSelect";
import Table from "../../components/Table";
import GenericText from "../../components/texts/GenericText";
import useDebounce from "../../helpers/hooks/useDebounce";
import changeStateByInputName from "../../helpers/state/changeStateByInputName";
import CinemaService from "../../services/api/cinema/CinemaService";
import queryKeys from "../../services/utils/queryKeys";
import { cinemaHome } from "./Tables/cinemaHome";

const Cinema = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<Record<string, any>>({
    active: true,
  });
  const debounceSearch = useDebounce(filters?.search, 1000);

  const { data: sumarry } = useQuery({
    queryKey: [queryKeys.cinema.brand.numbers],
    queryFn: CinemaService.getSummary,
  });

  const { data: dataTable } = useQuery({
    queryKey: [queryKeys.cinema.brand.list, limit, debounceSearch, page, filters.active],
    queryFn: () =>
      CinemaService.getBrand({
        page,
        limit,
        search: debounceSearch,
        active: filters.active,
      }),
  });

  return (
    <>
      <Box
        width={"99%"}
        margin={"10px 0px"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={sumarry?.active || ""}
            subTitle="Cinemas ativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={sumarry?.inactive || ""}
            subTitle="Cinemas inativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={sumarry?.suspended || ""}
            subTitle="Cinemas suspensos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={sumarry?.total || ""}
            subTitle="Total de cinemas"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
      </Box>
      <Seprator margin={"10px 0px"} />
      <Column width={"90%"} alignItems="flex-start">
        <Column width={"95%"} alignSelf="center">
          <GenericText font="Bold" size="h4" alignSelf="flex-start">
            Buscadores
          </GenericText>
          <Row justifyContent="space-between" margin={"30px 0px"}>
            <BoxSelect
              labelContainer="Status"
              value={
                filters.active
                  ? { id: 1, name: "Ativo" }
                  : {
                      id: 2,
                      name: "Inativo",
                    }
              }
              data={[
                {
                  id: 1,
                  name: "Ativo",
                },
                {
                  id: 2,
                  name: "Inativo",
                },
              ]}
              width="90%"
              fatherWidth="500px"
              placeholder="Selecione o status"
              getOptionLabel={(item: any) => item?.name}
              getOptionValue={(item: any) => item?.id}
              onChange={(e: any) => {
                setFilters((prev) => ({
                  ...prev,
                  active: e.id === 1 ? true : false,
                }));
              }}
            />
          </Row>
        </Column>
      </Column>
      <Seprator />
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          onRowClicked={(row: any) => {
            navigate(`/cinema/detalhes/${row?.id}`);
          }}
          data={dataTable?.data || []}
          columns={cinemaHome({
            onClick: (row: any) => navigate(`/cinema/detalhes/${row?.id}`),
          })}
          progressPending={!dataTable}
          selectableRows
          pagination
          paginationTotalRows={dataTable?.total}
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            setPage(newPage);
          }}
          headerComponents={[
            <Input
              label="Busca"
              name="search"
              onChange={(e) => changeStateByInputName(e, setFilters)}
              width="280px"
              placeholder="Digite a informação para busca"
              margin={"5px 5px"}
            />,
          ]}
        />
      </Box>
    </>
  );
};

export default Cinema;
