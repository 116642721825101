/* eslint-disable import/no-anonymous-default-export */
import CreateAndUpateFinancial from "./CreateAndUpateFinancial";
import GetFinancialCustomerDetails from "./GetFinancialCustomerDetails";

class service {
  getFinancialCustomerDetails = GetFinancialCustomerDetails;
  createAndUpateFinancial = CreateAndUpateFinancial;
}

export default new service();
