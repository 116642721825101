/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IProfile } from "./interface/IProfile";

const GetProfile = async (): Promise<IProfile> => {
  const URL = queryFormatter({
    route: "starbase/user/profile",
    querys: {},
  });
  try {
    const { data }: AxiosResponse<IProfile> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetProfile;
