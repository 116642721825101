import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { StorageNames } from "../../../constants/storage/StorageNames";
import { IProfile } from "../../../interfaces/authentication/IProfile";
import AuthenticationFetchs from "../../../services/api/authentication/AuthenticationFetchs";
import { axiosProvider } from "../../../services/utils/axiosProvider";
import queryKeys from "../../../services/utils/queryKeys";
import { IChangeTokenOptions } from "./interfaces/IChangeTokenOptions";
import { ITokenController } from "./interfaces/ITokenController";

export default function useTokenController() {
  const [token, setToken] = useState(
    localStorage.getItem(StorageNames.token) || ""
  );

  async function changeToken(
    token: string,
    options: IChangeTokenOptions = {}
  ): Promise<void> {
    if (options.logout) {
      localStorage.removeItem(StorageNames.token);
      setToken(token);
      window.location.reload();
      return;
    }
    localStorage.setItem(StorageNames.token, String(token));
    setToken(token);
  }
  function logout({ toast }: { toast?: string } = {}) {
    changeToken("", { logout: true, toast });
  }
  const { data: profile = {} } = useQuery({
    queryKey: [queryKeys.profile.list],
    queryFn: AuthenticationFetchs.profile,
    enabled: !!token,
  });
  axiosProvider.defaults.headers.common["Authorization"] = token;
  axiosProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        console.log("Caiuuuu");
        logout();
        console.log({ token });
        return;
      }
      throw error;
    }
  );

  const response: ITokenController = useMemo(
    () => ({ token, changeToken, logout, profile: profile as IProfile }),
    [token, profile]
  );

  return response;
}
