import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { currencyMask } from "../../../../../helpers/mask/currencyMask";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import FinancialService from "../../../../../services/api/financial/FinancialService";
import queryKeys from "../../../../../services/utils/queryKeys";
import { days } from "./days";

export default function FinancialInformation({ id }: { id: number }) {
  const { data } = useQuery({
    queryKey: [queryKeys.financial.details , id],
    queryFn: () => FinancialService.getFinancialCustomerDetails(id),
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [queryKeys.customer.exotic.updateFinancial],
    mutationFn: ({ id, body }: { id: number; body: any }) =>
      FinancialService.createAndUpateFinancial(id, body),
    onSuccess: () => toast.success("Dados salvos com sucesso!"),
  });

  const [minBill, setMinBill] = useState("");
  const [fixedBill, setFixedBill] = useState("");
  const [unitValue, setUniValue] = useState("");

  const [initialObject, setInitialObject] = useState<Record<string, any>>({});

  const [financial, setFinancial] = useState<Record<string, any>>({});

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: financial,
  });

  const changeStateFinancial = (e: any) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFinancial((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const convertCurrencyStringToNumber = (
    currencyString: string,
    isUnitValue = false
  ) => {
    if (!currencyString || currencyString === "R$ 0,00") return null;
    let cleanValue = currencyString.replace("R$", "").trim();
    cleanValue = cleanValue.replace(/\./g, "").replace(",", ".");
    if (isUnitValue) {
      return parseFloat(cleanValue);
    }

    return parseFloat(cleanValue);
  };

  const update = () => {
    const body = {
      ...financial,
      unitValue: convertCurrencyStringToNumber(financial.unitValue, true),
      minValue: convertCurrencyStringToNumber(financial.minBill),
      fixedValue: convertCurrencyStringToNumber(financial.fixedBill),
      minBill: convertCurrencyStringToNumber(financial.minBill),
    };
    mutate({ id, body });
    setInitialObject(financial);
  };
  const formatCurrency = (value: number | null) => {
    const tempInput = { value: value?.toString() || "0" };
    let formattedValue = "";

    currencyMask({
      target: tempInput,
      setValue: (val) => {
        formattedValue = val;
      },
    });

    return formattedValue || "R$ 0,00";
  };
  useEffect(() => {
    if (data) {
      setUniValue(formatCurrency(data.unitValue));
      setMinBill(formatCurrency(data.minValue));
      setFixedBill(
        data.fixedValue ? formatCurrency(data.fixedValue) : "R$ 0,00"
      );

      setFinancial({
        unitValue: formatCurrency(data.unitValue),
        minBill: formatCurrency(data.minValue),
        fixedBill: data.fixedValue
          ? formatCurrency(data.fixedValue)
          : "R$ 0,00",
        iss: data.iss,
        payday: data.payday,
      });

      setInitialObject({
        unitValue: formatCurrency(data.unitValue),
        minBill: formatCurrency(data.minValue),
        fixedBill: data.fixedValue
          ? formatCurrency(data.fixedValue)
          : "R$ 0,00",
        iss: data.iss,
        payday: data.payday,
      });
    }
  }, [data]);

  const handleCurrencyChange = ({
    target,
    setValue,
    setFinancial,
  }: {
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement);
    setValue: (value: string) => void;
    setFinancial: React.Dispatch<React.SetStateAction<any>>;
  }) => {
    currencyMask({
      target,
      setValue: (formattedValue: string) => {
        setValue(formattedValue);

        setFinancial((prev: any) => ({
          ...prev,
          [target.name]: formattedValue
            .replace("R$ ", "")
            .replace(/\./g, "")
            .replace(",", "."),
        }));
      },
    });
  };

  return (
    <SubmitEditsForm
      title="Financeiro"
      buttonProps={{
        onClick: update,
        loading: isPending,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="unitValue"
            value={unitValue}
            width={"100%"}
            label="Valor Unitário"
            placeholder="R$ 0,00"
            type="currency"
            margin={"5px 0px"}
            onChange={(e) =>
              handleCurrencyChange({
                target: e.target,
                setValue: setUniValue,
                setFinancial,
              })
            }
          />

          <Input
            name="minBill"
            value={minBill}
            width={"100%"}
            label="Boleto Mínimo"
            placeholder="R$ 0,00"
            type="currency"
            margin={"5px 0px"}
            onChange={(e) =>
              handleCurrencyChange({
                target: e.target,
                setValue: setMinBill,
                setFinancial,
              })
            }
          />

          <Input
            name="fixedBill"
            value={fixedBill}
            width={"100%"}
            label="Boleto Fixo"
            placeholder="R$ 0,00"
            type="currency"
            margin={"5px 0px"}
            onChange={(e) =>
              handleCurrencyChange({
                target: e.target,
                setValue: setFixedBill,
                setFinancial,
              })
            }
          />
          <Input
            name="iss"
            value={financial.iss}
            width={"100%"}
            label="ISS"
            type="percentage"
            margin={"5px 0px"}
            placeholder="0%"
            onChange={(e) => changeStateFinancial(e)}
          />
          <Select
            data={days}
            name="dueDate"
            width={"100%"}
            label="Dia de Pagamento"
            value={days.find((day) => day.id === financial?.payday)}
            placeholder="Selecione o dia de pagamento"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => {
              setFinancial((prev) => ({
                ...prev,
                payday: e.id,
              }));
            }}
          />
        </>
      )}
    />
  );
}
