/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IAccess } from "./interface/IAccess";

const UpdateAccess = async ({
  body,
  id,
}: {
  body: Omit<IAccess, "id">;
  id: number;
}): Promise<IAccess> => {
  const URL = queryFormatter({
    route: `dashboard/access/${id}`,
  });
  try {
    console.log("***body", body);
    const { data }: AxiosResponse<IAccess> = await axiosProvider.put(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateAccess;
