import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../components/buttons/Button";
import Box from "../../../../components/containers/Box";
import { CSCContainer } from "../../../../components/containers/ContentSpacingContainer/styles";
import Row from "../../../../components/containers/Row";
import LoaderGlobal from "../../../../components/Exotics/loaders/LoaderGlobal";
import GenericText from "../../../../components/texts/GenericText";
import decodeUrlJson from "../../../../helpers/urls/decodeUrlJson";
import encodeUrlJson from "../../../../helpers/urls/encodeUrlJson";
import useQueryParams from "../../../../hooks/helpers/useQueryParams";
import { IPush } from "../../../../interfaces/Push/INotification";
import NotificationFetchs from "../../../../services/api/notification/NotificationFetchs";
import queryKeys from "../../../../services/utils/queryKeys";
import checkFirstStep from "./checks/checkFirstStep";
import checkFourthStep from "./checks/checkFourthStep";
import checkSecondStep from "./checks/checkSecondStep";
import checkThirdStep from "./checks/checkThirdStep";
import FirstStepPush from "./components/FirstStepPush";
import FourthStepPush from "./components/FourthStepPush";
import SecondStepPush from "./components/SecondStepPush";
import ThirdStepPush from "./components/ThirdStepPush";

export default function CreatePush() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [push, setPush] = useState<Record<string, any>>({});
  const [step, setStep] = useState(Number(params.step) || 1);
  const [image, setImage] = useState<File>();
  const [disabled, setDisabled] = useState(true);
  const { mutate, isSuccess, isError, isPending } = useMutation({
    mutationFn: NotificationFetchs.create,
    mutationKey: [queryKeys.notifications.create],
  });
  async function executeNotify() {
    console.log({ push });

    mutate(push as IPush);
  }
  useEffect(() => {
    if (params.cache) {
      const data = decodeUrlJson(params.cache);
      setPush(data);
    }
  }, [params.step]);

  useEffect(() => {
    navigate(
      `/marketing/notificacao/push?step=${step}&cache=${encodeUrlJson(push)}`
    );
  }, [push, step]);

  function checkByStep(object: Record<string, any>) {
    if (step === 1) {
      return checkFirstStep(object);
    }
    if (step === 2) {
      return checkSecondStep(object) || !image;
    }
    if (step === 3) {
      return checkThirdStep(object);
    }
    if (step === 4) {
      return checkFourthStep(object);
    }

    return false;
  }
  useEffect(() => {
    setDisabled(checkByStep(push));
  }, [
    push,
    push?.checkButton,
    push?.body,
    push?.body?.components?.button?.type,
    push?.body?.components?.copy?.code,
  ]);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Notificação enviada com sucesso!");
      setPush({});
      setStep(1);
    }
  }, [isSuccess]);
  function changeImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files) return;
    setImage(e.target.files[0]);
  }
  return (
    <Box
      style={{ padding: "20px" }}
      direction="column"
      width={"90%"}
      margin={"20px 0px"}
      shadow
    >
      <GenericText font="Bold" size={"h4"}>
        Criar notificação push
      </GenericText>
      <CSCContainer>
        {step === 1 && <FirstStepPush push={push} changePush={setPush} />}
        {step === 2 && (
          <SecondStepPush
            push={push}
            changePush={setPush}
            changeImage={changeImage}
          />
        )}
        {step === 3 && <ThirdStepPush push={push} changePush={setPush} />}
        {step === 4 && <FourthStepPush push={push} changePush={setPush} />}
      </CSCContainer>
      <Row margin={"20px 0px 0px 0px"} justifyContent="space-between">
        <Button
          text="Anterior"
          width={"100px"}
          height={"40px"}
          alignSelf="flex-end"
          disabled={step === 1}
          onClick={() => setStep((el) => el - 1)}
          style={{ background: step === 1 ? "#f3f3f3" : "blue" }}
        />
        <Button
          text="Próximo"
          width={"200px"}
          height={"40px"}
          disabled={disabled}
          alignSelf="flex-end"
          onClick={() => {
            console.log({ step });
            if (step === 4) {
              executeNotify();
              return;
            }
            setStep((el) => el + 1);
          }}
        />
      </Row>
      {isPending && <LoaderGlobal />}
    </Box>
  );
}
