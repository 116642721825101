/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { ICreateEmployee } from "./interface/IEmployee";

const CreateEmployee = async (body: ICreateEmployee): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/user`,
    querys: {},
  });
  try {
    const { data }: AxiosResponse<void> = await axiosProvider.post(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default CreateEmployee;
