import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import UtilsService from "../../services/api/utils/UtilsService";
import queryKeys from "../../services/utils/queryKeys";
import { IUseGetCnpjController } from "./interfaces/IUseGetCnpjController";

export default function useGetCnpjController() {
  const [cnpj, setCnpj] = useState("");

  const {
    data,
    isLoading: isLoadingCnpj,
    isSuccess,
  } = useQuery({
    queryKey: [queryKeys.global.exotic.cnpj],
    queryFn: () => UtilsService.getCnpj(cnpj),
    enabled: !!cnpj,
  });

  const response: IUseGetCnpjController = useMemo(
    () => ({
      isLoadingCnpj,
      data,
      setCnpj,
      isSuccess,
    }),
    [isLoadingCnpj, data, isSuccess]
  );
  return response;
}
