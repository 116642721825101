import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { tableRoverOpacity } from "../../helpers/table/tableRoverOpacity";
import Row from "../containers/Row";
import LoaderGlobal from "../Exotics/loaders/LoaderGlobal";
import GenericText from "../texts/GenericText";
import { ITable } from "./interface/ITable";

const Table = (props: ITable) => {
  const [loadingMap, setLodingMap] = useState(false);
  const {
    headerComponents,
    onRowMouseEnter = (row: {}, e: React.MouseEvent) => {},
    onRowMouseLeave = (row: {}, e: React.MouseEvent) => {},
    data = [],
  } = props;

  const [row, setRow] = useState<number | null>(null);

  const newData = useMemo(() => {
    setLodingMap(true);
    const response = data.map((item, index) => ({ ...item, index }));
    setLodingMap(false);
    return response;
  }, [data]);

  return (
    <div
      style={{
        boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.2)",
        width: "100%",
        padding: "16px",
        borderRadius: "10px",
      }}
    >
      <Row alignItems="center" width="100%" justifyContent="space-between">
        {headerComponents?.map((input, i) =>
          React.cloneElement(input, { key: `list-component-dynamic-${i}` })
        )}
      </Row>
      <DataTable
        {...props}
        progressPending={props.progressPending || loadingMap}
        progressComponent={<LoaderGlobal />}
        paginationServer={props.paginationServer ?? true}
        pagination={props.pagination ?? true}
        conditionalRowStyles={tableRoverOpacity(row)}
        selectableRows={false}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
        noDataComponent={
          <GenericText>Sem dados disponíveis no momento</GenericText>
        }
        customStyles={{
          table: {
            style: {
              fontFamily: "Noto Sans, sans-serif",
            },
          },
        }}
        data={newData}
        onRowMouseEnter={(row: Record<string, any>, e) => {
          setRow(row?.index);
          onRowMouseEnter(row, e);
        }}
        onRowMouseLeave={(row: Record<string, any>, e) => {
          setRow(null);
          onRowMouseLeave(row, e);
        }}
      />
    </div>
  );
};

export default Table;
