/* eslint-disable import/no-anonymous-default-export */
import CreateAccess from "./createAcess";
import GetAccess from "./getAcess";
import GetOneAccess from "./GetOneAccess";
import UpdateAccess from "./UpdateAccess";

class service {
  getAccess = GetAccess;
  createAccess = CreateAccess;
  getOneAccess = GetOneAccess;
  updateAccess = UpdateAccess;
}

export default new service();
