/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Chip from "../../../../../components/Chip";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import convertEmptyStringsToNullOrDelete from "../../../../../helpers/object/convertEmptyStringsToNull ";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";
import queryKeys from "../../../../../services/utils/queryKeys";

const BasicInformation = ({ basic }: { basic: IBrandDetails }) => {
  const [chips, setChips] = useState<string[]>([]);
  const [initialChip, setInitialChip] = useState<string[]>([]);
  const [keywords, setKeywords] = useState("");
  const [details, setDetails] = useState<Record<string, any>>({
    establishmentInfo: {},
  });
  const [initialObject, setInitialObject] = useState({});

  const disabledButton = useVerifyObjectEqual({
    initialObject,
    actualObject: details,
  });
  const disableButtonChips = useVerifyObjectEqual({
    initialObject: { chips: initialChip },
    actualObject: { chips: chips },
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.cinema.brand.update],
    mutationFn: CinemaService.updateCinema,
  });

  const changeStateInformation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDetails((prev) => ({
      ...prev,
      establishmentInfo: {
        ...prev.establishmentInfo,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const init = () => {
    const object = {
      id: basic.id,
      name: basic.name,
      about: basic.about,
      rules: basic.rules,
    };
    setInitialObject({ establishmentInfo: object });
    setDetails({ establishmentInfo: object });
    const chips = basic.keyWords
      .split(/<br\/?>/)
      .map((chip: string) => chip.trim())
      .filter((chip: string) => chip !== "");
    setInitialChip(chips);
    setChips(chips);
  };

  const handleAddChip = () => {
    setChips((prev) => [...prev, keywords]);
    setDetails((prev) => ({
      ...prev,
      establishmentInfo: {
        ...prev.establishmentInfo,
      },
    }));
    setKeywords("");
  };

  const handleDeleteChip = (index: number) => {
    setChips(chips.filter((_chip, i) => i !== index));
  };

  const update = () => {
    const updateStates = {
      establishmentInfo: {
        ...details.establishmentInfo,
      },
    };

    const body = {
      establishmentInfo: {
        ...details.establishmentInfo,
        keyWords: chips.join("<br/>"),
      },
    };

    const clearObject = convertEmptyStringsToNullOrDelete(body, true);
    mutate(clearObject as Omit<IBrandDetails, "images">);
    setInitialObject(updateStates);
    setDetails(updateStates);
    setChips(chips);
    setInitialChip(chips);
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Cinema editado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <SubmitEditsForm
        title="Informações"
        buttonProps={{
          disabled: disabledButton && disableButtonChips,
          loading: isPending,
          onClick: update,
        }}
        renderInsideComponents={() => (
          <>
            <Input
              name="name"
              value={details.establishmentInfo.name}
              onChange={(e) => changeStateInformation(e)}
              width={"100%"}
              label="Nome"
              type="text"
              margin={"5px 0px"}
            />
            <Input
              type="textArea"
              rows={7}
              name="about"
              label="Sobre"
              placeholder="Descrição sobre o cinema"
              value={details.establishmentInfo.about}
              onChange={(e) => changeStateInformation(e)}
              margin={"5px 0px"}
            />

            <Input
              type="textArea"
              rows={7}
              name="rules"
              label="Regras"
              placeholder="Regras do cinema"
              value={details.establishmentInfo.rules}
              onChange={(e) => changeStateInformation(e)}
              margin={"5px 0px"}
            />
            <Input
              name="keywords"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddChip();
                }
              }}
              width={"100%"}
              label="Tags ( Pressione a tecla ENTER )"
              placeholder="Pressione enter para cadastrar a tag"
              type="text"
              margin={"5px 0px"}
            />
            <div>
              {chips.map((chip, index) => (
                <Chip label={chip} onDelete={() => handleDeleteChip(index)} />
              ))}
            </div>
          </>
        )}
      />
    </>
  );
};

export default BasicInformation;
