import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../components/Card";
import Row from "../../../components/containers/Row";
import { Seprator } from "../../../components/Exotics/Seprator";
import Table from "../../../components/Table";
import UsersFetchs from "../../../services/api/users/UsersFetchs";
import queryKeys from "../../../services/utils/queryKeys";
import { UserTableConfig } from "../tables/config";

export default function UsersList() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: summary } = useQuery({
    queryKey: [queryKeys.users.numbers, { id }],
    queryFn: () => UsersFetchs.summary({ id }),
    enabled: !!id,
  });
  const { data: users, isLoading } = useQuery({
    queryKey: [queryKeys.users.list, { id, page, limit }],
    queryFn: () => UsersFetchs.search({ companyId: id, page, limit }),

    enabled: !!id,
  });

  return (
    <>
      <Row justifyContent="space-around" margin={"10px 0px"}>
        <Card
          title={summary?.activeUsers.toString() || ""}
          subTitle="Usuários ativos"
          backgroundColor="white"
        />
        <Card
          title={summary?.inactiveUsers.toString() || ""}
          subTitle="Usuários inativos"
          backgroundColor="white"
        />
        <Card
          title={Number(summary?.activeUsers) + Number(summary?.inactiveUsers)}
          subTitle="Usuários totais"
          backgroundColor="white"
        />
      </Row>
      <Seprator margin="20px 0px" />

      <Row margin={"20px 0px"} width={"90%"}>
        <Table
          data={users?.data || []}
          columns={UserTableConfig() as never}
          paginationTotalRows={users?.count}
          progressPending={isLoading}
          onRowClicked={(row: any) => {
            navigate(`/associados/detalhes/${row.companyId}-${row.cpf}`);
          }}
          selectableRows
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            setPage(newPage);
          }}
        />
      </Row>
    </>
  );
}
