import GenericText from "../texts/GenericText";
import ISelect from "./ISelect";
import { customStyles, SelectCustom } from "./styles";

const Select = (props: ISelect) => {
  const { label } = props;
  return (
    <div style={{ width: props.width || "100%" }}>
      {label && (
        <GenericText margin="0px 0px 4px 0px" size="h6" color="black">
          {`${props.label}${props.required ? "*" : ""}`}
        </GenericText>
      )}
      <SelectCustom
        noOptionsMessage={() => "Sem Dados"}
        loadingMessage={() => "Pesquisando..."}
        menuPosition={"fixed"}
        isLoading={props.isLoading}
        styles={customStyles}
        options={props.data}
        {...props}
      />
    </div>
  );
};

export default Select;
