/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IGetEmployees } from "./interface/IEmployee";

const GetEmployee = async ({
  limit,
  page,
}: {
  page: number;
  limit: number;
}): Promise<IGetEmployees> => {
  const URL = queryFormatter({
    route: `dashboard/user`,
    querys: { limit, page: (page ?? 1) - 1 },
  });

  try {
    const { data }: AxiosResponse<IGetEmployees> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetEmployee;
