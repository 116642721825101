/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CheckBoxImage from "../../../../../components/chekbox/CheckBoxImage";
import { Seprator } from "../../../../../components/Exotics/Seprator";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import queryKeys from "../../../../../services/utils/queryKeys";

const ProductsInformation = ({ basic }: { basic: ICustomer }) => {
  const { companiesProducts } = basic;
  const [selectedProducts, setSelectedProducts] = useState<{
    products: number[];
  }>({ products: [] });
  const [initialObject, setInitialObject] = useState<{ products: number[] }>({
    products: [],
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.customer.product.update],
    mutationFn: CustomerService.updateProducts,
  });

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: selectedProducts,
  });

  const handleCheckboxChange = (id: number) => {
    setSelectedProducts((prevState) => ({
      products: prevState.products.includes(id)
        ? prevState.products.filter((productId) => productId !== id)
        : [...prevState.products, id],
    }));
  };

  const update = () => {
    setInitialObject(selectedProducts);
    mutate({ id: basic.id, body: selectedProducts });
  };

  useEffect(() => {
    if (basic) {
      const initialSelectedIds = companiesProducts
        .filter((product) => product.active)
        .map((product) => product.id);
      setSelectedProducts({ products: initialSelectedIds });
      setInitialObject({ products: initialSelectedIds });
    }
  }, [basic, companiesProducts]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Produtos"
      buttonProps={{
        onClick: update,
        loading: isPending,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Seprator />
          {companiesProducts.map((product, i) => (
            <CheckBoxImage
              key={`list-checkBoxImage-products-${i}`}
              image={product?.icon}
              alt={product.appName}
              checked={selectedProducts.products.includes(product.id)}
              onChange={() => handleCheckboxChange(product.id)}
            />
          ))}
        </>
      )}
    />
  );
};

export default ProductsInformation;
