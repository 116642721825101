/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import { currencyMask } from "../../../../../helpers/mask/currencyMask";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";
import queryKeys from "../../../../../services/utils/queryKeys";

const ProductInformation = ({ basic }: { basic: IBrandDetails }) => {
  const [price, setPrice] = useState("");
  const [details, setDetails] = useState<Record<string, any>>({
    productInfo: {},
  });

  const { data: productType } = useQuery({
    queryKey: [queryKeys.cinema.products.exotic.productType],
    queryFn: CinemaService.getProductType,
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.cinema.products.create],
    mutationFn: CinemaService.createProduct,
    onSuccess: () => {
      setPrice("");
      setDetails({
        productInfo: {
          establishmentId: basic.id,
          name: "",
          rules: "",
          description: "",
          soldOutAmount: null,
          type: null,
          tags: [],
        },
      });
    },
  });

  const convertCurrencyStringToNumber = (currencyString: string) => {
    if (currencyString === "R$ 0,00") return "";
    return parseFloat(
      currencyString.replace("R$", "").replace(",", ".").trim()
    );
  };

  const changeStateProduct = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDetails((prev) => ({
      productInfo: {
        ...prev.productInfo,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const disabledButton = useMemo(() => {
    if (
      price.length &&
      details.productInfo?.name.length &&
      details.productInfo?.soldOutAmount &&
      details.productInfo?.type
    )
      return false;
    return true;
  }, [
    price.length,
    details.productInfo?.soldOutAmount,
    details.productInfo?.type,
    details.productInfo?.name,
  ]);

  const init = () => {
    const object = {
      establishmentId: basic.id,
      name: "",
      rules: "",
      description: "",
      soldOutAmount: null,
      type: null,
      tags: [],
    };
    setDetails({ productInfo: object });
  };

  const update = () => {
    const body = {
      productInfo: {
        ...details.productInfo,
        price: convertCurrencyStringToNumber(price),
        soldOutAmount: Number(details.productInfo.soldOutAmount),
      },
    };
    mutate({ body });
    setDetails(details);
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Produto criado com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Cadastrar produto"
      buttonProps={{
        disabled: disabledButton,
        loading: isPending,
        onClick: update,
      }}
      renderInsideComponents={() => (
        <>
          <Select
            required
            label="Tipo de Produto"
            placeholder="Selecione o tipo de produto"
            value={
              productType?.data?.filter(
                (prod) => prod.id === details.productInfo.type
              ) || {}
            }
            data={
              productType?.data.filter(
                (product) => product.appName !== "Combos"
              ) || []
            }
            getOptionLabel={(option: any) => option.appName}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => {
              setDetails((prev) => ({
                productInfo: {
                  ...prev.productInfo,
                  type: e.id,
                },
              }));
            }}
          />
          <Input
            required
            value={details.productInfo?.name}
            type="text"
            name="name"
            label="Nome"
            placeholder="Digite o nome do produto"
            width={"100%"}
            margin={"5px 0px"}
            onChange={(e) => changeStateProduct(e)}
          />
          <Input
            name="description"
            value={details.productInfo?.description}
            type="textArea"
            placeholder="Digite a descriçao do produto"
            rows={3}
            label="Descriçao"
            onChange={(e) => changeStateProduct(e)}
          />
          <Input
            name="rules"
            value={details.productInfo?.rules}
            type="textArea"
            placeholder="<p>Digite as regras do prduto<p/>"
            rows={7}
            label="Regras"
            onChange={(e) => changeStateProduct(e)}
          />
          <Input
            required
            name="soldOutAmount"
            value={details.productInfo?.soldOutAmount ?? ""}
            type="number"
            placeholder="Digite o valor para esgotar o produto"
            label="Valor esgotado"
            width={"100%"}
            margin={"5px 0px"}
            onChange={(e) => changeStateProduct(e)}
          />
          <Input
            required
            name="price"
            value={price}
            type="text"
            placeholder="Digite o valor do produto"
            width={"100%"}
            margin={"5px 0px"}
            label="Valor"
            onChange={({ target }) => {
              currencyMask({ target, setValue: setPrice });
            }}
          />
        </>
      )}
    />
  );
};
export default ProductInformation;
