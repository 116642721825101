import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "../context/AuthProvider";
import LocationProvider from "../context/LocationProvider";
import Layout from "../layouts";
import Access from "../pages/Access";
import AccessDetails from "../pages/Access/Details";
import AccessRegistration from "../pages/Access/Registration";
import Associates from "../pages/Associates";
import DetailsAssociate from "../pages/Associates/Details";
import RegisterAssociate from "../pages/Associates/Registration";
import Client from "../pages/B2B";
import Cinema from "../pages/Cinema";
import CinemaDetails from "../pages/Cinema/Details";
import OrderDetails from "../pages/Cinema/Details/Orders";
import Vouchers from "../pages/Cinema/Details/Vouchers";
import Orders from "../pages/Cinema/Orders";
import Customers from "../pages/Customers";
import Details from "../pages/Customers/Details";
import Registration from "../pages/Customers/Registration";
import Employees from "../pages/Employees";
import EmployeesRegistration from "../pages/Employees/Registration";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Plans from "../pages/marketing/Plans";
import PlansDetails from "../pages/marketing/Plans/Details";
import CreatePush from "../pages/marketing/Push/Create";
import PlayGround from "../pages/PlayGround";
import UsersList from "../pages/users/List";
import Public from "./Public";
import SvaCreate from "../pages/marketing/sva/create/indes";

const Routing = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutos
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
    mutationCache: new MutationCache({
      onError: (error, _variables, _context, mutation) => {
        if (mutation.options.onError) return;
        const errorMessage = error.message;
        toast.error(errorMessage);
      },
    }),
    queryCache: new QueryCache({}),
  });
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <ToastContainer position="top-center" />
        <AuthProvider>
          <LocationProvider>
            <Layout>
              <Routes>
                <Route
                  path="*"
                  element={
                    <Public >
                      <Home />
                    </Public>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Public>
                      <Home />
                    </Public>
                  }
                />
                {/*    Rotas de clientes B2B */}
                <Route path="cliente/:id" element={<Client />}></Route>

                <Route path="clientes">
                  <Route
                    path="home"
                    element={
                      <Public>
                        <Customers />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/:id"
                    element={
                      <Public>
                        <Details />
                      </Public>
                    }
                  />
                  <Route
                    path="cadastro"
                    element={
                      <Public>
                        <Registration />
                      </Public>
                    }
                  />
                </Route>
                <Route path="associados">
                  <Route
                    path="home/:id?/:active?"
                    element={
                      <Public>
                        <Associates />
                      </Public>
                    }
                  />
                  <Route
                    path="cadastro"
                    element={
                      <Public>
                        <RegisterAssociate />
                      </Public>
                    }
                  />
                  <Route
                    path="cadastro/:id"
                    element={
                      <Public>
                        <RegisterAssociate />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/:id"
                    element={
                      <Public>
                        <DetailsAssociate />
                      </Public>
                    }
                  />
                </Route>
                <Route path="usuarios">
                  <Route
                    path="lista/:id"
                    element={
                      <Public>
                        <UsersList />
                      </Public>
                    }
                  />
                </Route>

                <Route path="cinema">
                  <Route
                    path="home"
                    element={
                      <Public>
                        <Cinema />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/:id"
                    element={
                      <Public>
                        <CinemaDetails />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/ingressos/:id"
                    element={
                      <Public>
                        <Vouchers />
                      </Public>
                    }
                  />
                  <Route
                    path="pedidos"
                    element={
                      <Public>
                        <Orders />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/pedido/:id"
                    element={
                      <Public>
                        <OrderDetails />
                      </Public>
                    }
                  />
                </Route>
                <Route path="cargos">
                  <Route
                    path="home"
                    element={
                      <Public>
                        <Access />
                      </Public>
                    }
                  />
                  <Route
                    path="cadastro"
                    element={
                      <Public>
                        <AccessRegistration />
                      </Public>
                    }
                  />
                  <Route
                    path="detalhes/:id"
                    element={
                      <Public>
                        <AccessDetails />
                      </Public>
                    }
                  />
                </Route>
                <Route path="funcionarios">
                  <Route
                    path="home"
                    element={
                      <Public>
                        <Employees />
                      </Public>
                    }
                  />
                  <Route
                    path="cadastro"
                    element={
                      <Public>
                        <EmployeesRegistration />
                      </Public>
                    }
                  />
                </Route>
              </Routes>
              <Routes>
                <Route path="marketing">
                  <Route path="notificacao">
                    <Route
                      path="push/"
                      element={
                        <Public>
                          <CreatePush />
                        </Public>
                      }
                    />
                  </Route>
                  <Route path="sva">
                    <Route
                      path="cadastro"
                      element={
                        <Public>
                          <SvaCreate/>
                        </Public>
                      }
                    />
                  </Route>
                  <Route path="planos/:id?" element={<Plans />} />
                  <Route
                    path="planos/detalhes/:id"
                    element={<PlansDetails />}
                  />
                </Route>
              </Routes>
              <Routes>
                <Route path="autenticacao">
                  <Route path="login" element={<Login />} />
                </Route>
                <Route path="play-ground" element={<PlayGround />} />
              </Routes>
            </Layout>
          </LocationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </HashRouter>
  );
};

export default Routing;
