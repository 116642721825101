/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";
import useLocation from "../../../../hooks/useLocation";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import {
  ICustomer,
  ISegment,
  IState,
} from "../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../services/api/customer/CustomerService";
import ICities from "../../../../services/api/utils/interfaces/ICities";
import queryKeys from "../../../../services/utils/queryKeys";

const BasicInformation = ({ basic }: { basic: ICustomer }) => {
  const { states, isLoadingStates, cities, getCities } = useLocation();

  const [details, setDetails] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState({});
  const [selectState, setSelectState] = useState<IState>();
  const [selectCities, setSelectCites] = useState<ICities>();
  const [selectSegment, setSelectSegment] = useState<ISegment>({
    id: 0,
    name: "",
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.customer.update],
    mutationFn: CustomerService.update,
  });

  const { data: segments, isLoading: isLoadingSegment } = useQuery({
    queryKey: [queryKeys.customer.segment.list],
    queryFn: () => CustomerService.getSegments(),
  });

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: details,
  });

  function init() {
    const object = {
      name: basic.name,
      corporateName: basic.corporateName,
      cnpj: basic.cnpj,
      email: basic.email,
      segmentId: basic.segment?.id,
      stateId: basic.state?.id,
      cityId: basic.city?.id,
      contract: basic.contract,
    };
    setDetails(object);
    setInitialObject(object);
  }
  async function update() {
    setInitialObject(details);
    mutate({ id: basic.id, update: details });
  }

  useEffect(() => {
    basic && init();
    basic && setSelectState(basic?.state);
    basic && setSelectSegment(basic?.segment);
    basic && setSelectCites(basic?.city);
  }, [basic]);

  useEffect(() => {
    if (selectState?.id) {
      getCities(selectState?.id.toString());
    }
  }, [selectState?.id]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso");
    }
  }, [isSuccess]);
  return (
    <SubmitEditsForm
      title="Informações"
      buttonProps={{
        onClick: update,
        loading: isPending,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="name"
            value={details.name}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            width={"100%"}
            label="Nome"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="corporateName"
            value={details.corporateName || ""}
            width={"100%"}
            label="Razão Social"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            maskType="cnpj"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="cnpj"
            value={details.cnpj || ""}
            width={"100%"}
            label="Cnpj/Cpf"
            type="text"
            disabled
            margin={"5px 0px"}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="email"
            value={details.email || ""}
            width={"100%"}
            label="Email"
            type="text"
            margin={"5px 0px"}
            placeholder="Email para contato do cliente"
          />

          <Select
            data={segments || []}
            value={selectSegment}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => {
              const updateDetails = {
                ...details,
                segmentId: e.id,
              };
              setDetails(updateDetails);
              setSelectSegment(e);
            }}
            label="Seguimento"
            name="segment"
            placeholder="Seguimento"
            isLoading={isLoadingSegment}
          />
          <Select
            data={states || []}
            value={selectState}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => {
              const updateDetails = {
                ...details,
                stateId: e.id,
              };
              setDetails(updateDetails);
              setSelectState(e);
            }}
            label="Estado"
            placeholder="Estado"
            isLoading={isLoadingStates}
          />
          <Select
            data={(cities[selectState?.id?.toString() as string] as any) || []}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            value={selectCities}
            onChange={(e: any) => {
              const updateDetails = {
                ...details,
                cityId: e.id,
              };
              setDetails(updateDetails);
              setSelectCites(e);
            }}
            label="Cidade"
            placeholder="Cidade"
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="contract"
            value={details.contract || ""}
            width={"100%"}
            disabled
            label="Contrato"
            type="text"
            margin={"5px 0px"}
            placeholder="Contrato de venda do cliente"
          />
        </>
      )}
    />
  );
};

export default BasicInformation;
