import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Row from "../../../components/containers/Row";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import RegistrationForm from "../../../components/forms/RegistrationForm";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import AccessService from "../../../services/api/access/AccessService";
import CustomerService from "../../../services/api/customer/CustomerService";
import EmployeeService from "../../../services/api/employees/EmployeeService";
import queryKeys from "../../../services/utils/queryKeys";

const EmployeesRegistration = () => {
  const [createEmployee, setCreateEmployee] = useState<Record<string, any>>({
    role: "admin",
  });
  const [companyName, setCompanyName] = useState("");

  const [mergedCompanies, setMergedCompanies] = useState<any[]>([]);

  const { data: companies, isLoading: isLoadingCompanies } = useQuery({
    queryKey: [queryKeys.customer.company.list, companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });
  const { data: access, isLoading: loadingAccess } = useQuery({
    queryKey: [queryKeys.access.list],
    queryFn: AccessService.getAccess,
  });

  const { mutate } = useMutation({
    mutationKey: [queryKeys.access.create],
    mutationFn: EmployeeService.createEmployee,
    onSuccess: () => {
      toast.success("Funcionário criado com sucesso");
      setCreateEmployee({
        role: "admin",
      });
    },
  });

  let timeoutRef: any;
  const handleCompanyName = (value: string) => {
    clearTimeout(timeoutRef);

    timeoutRef = setTimeout(() => {
      setCompanyName(value);
    }, 500);
  };

  const disabledButton = useMemo(() => {
    const keys = [
      "name",
      "email",
      "password",
      "role",
      "companyId",
      "accessId",
    ] as const;
    return keys.every(
      (key) => createEmployee.hasOwnProperty(key) && !!createEmployee[key]
    )
      ? false
      : true;
  }, [createEmployee]);

  useEffect(() => {
    if (companies?.data) {
      setMergedCompanies((prev) => {
        const newCompanies = companies.data.filter(
          (newCompany: any) =>
            !prev.some(
              (existingCompany: any) => existingCompany.id === newCompany.id
            )
        );
        return [...prev, ...newCompanies];
      });
    }
  }, [companies]);

  return (
    <>
      <RegistrationForm
        title="Cadastro De funcionário"
        buttonProps={{
          disabled: disabledButton,
          onClick: () => mutate(createEmployee as any),
        }}
        renderInsideComponents={() => (
          <>
            <Row
              wrap="wrap"
              width={"100%"}
              justifyContent="flex-start"
              style={{ gap: "15px" }}
            >
              <Input
                onChange={(e) => changeStateByInputName(e, setCreateEmployee)}
                name="name"
                value={createEmployee?.name || ""}
                width={"300px"}
                label="Nome"
                type="text"
                required
                placeholder="Nome"
              />
              <Select
                data={mergedCompanies}
                required
                getOptionLabel={(option: any) => option?.name}
                getOptionValue={(option: any) => option?.id}
                isLoading={isLoadingCompanies}
                onInputChange={(e) => {
                  handleCompanyName(e);
                }}
                onChange={(e: any) => {
                  setCreateEmployee((prev) => ({
                    ...prev,
                    companyId: e?.id || null,
                    companyName: e?.name || "",
                  }));
                }}
                value={
                  mergedCompanies.find(
                    (company: any) => company.id === createEmployee?.companyId
                  ) || null
                }
                placeholder="Empresa"
                label="Empresa"
                width={"300px"}
              />
              ;
              <Input
                onChange={(e) => changeStateByInputName(e, setCreateEmployee)}
                name="email"
                value={createEmployee?.email || ""}
                width={"300px"}
                label="Email"
                type="text"
                required
                placeholder="Email"
              />
              <Input
                onChange={(e) => changeStateByInputName(e, setCreateEmployee)}
                name="password"
                value={createEmployee?.password || ""}
                width={"300px"}
                label="Senha"
                type="text"
                required
                placeholder="Senha"
              />
              <Select
                data={access?.data || []}
                required
                getOptionLabel={(option: any) => option?.name}
                getOptionValue={(option: any) => option?.id}
                onInputChange={(e) => {
                  handleCompanyName(e);
                }}
                onChange={(e: any) => {
                  setCreateEmployee((prev) => ({
                    ...prev,
                    accessId: e?.id,
                  }));
                }}
                value={
                  access?.data?.find(
                    (item: any) => item.id === createEmployee?.accessId
                  ) || null
                }
                placeholder="Cargo"
                label="Cargo"
                width={"300px"}
              />
            </Row>
          </>
        )}
      />
      {loadingAccess && <LoaderGlobal />}
    </>
  );
};

export default EmployeesRegistration;
