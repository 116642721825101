/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { ICreateEmployee } from "./interface/IEmployee";

const UpdateEmployee = async ({
  body,
  id,
}: {
  body: ICreateEmployee;
  id: number;
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/user/${id}`,
    querys: {},
  });

  try {
    const { data }: AxiosResponse<void> = await axiosProvider.put(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};
export default UpdateEmployee;
