import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../components/Card";
import Box from "../../../components/containers/Box";
import Column from "../../../components/containers/Column";
import Row from "../../../components/containers/Row";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import { Seprator } from "../../../components/Exotics/Seprator";
import Input from "../../../components/Input";
import BoxSelect from "../../../components/Select/BoxSelect";
import Table from "../../../components/Table";
import GenericText from "../../../components/texts/GenericText";
import useDebounce from "../../../helpers/hooks/useDebounce";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import CustomerService from "../../../services/api/customer/CustomerService";
import PlansService from "../../../services/api/Plans/PlansService";
import queryKeys from "../../../services/utils/queryKeys";
import { plansHome } from "./tables/plansHome";

const Plans = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [seekers, setSeekers] = useState<Record<string, any>>({ status: true });
  const debounceSearch = useDebounce(seekers?.search, 1000);

  const { data: dataTable, isLoading } = useQuery({
    queryKey: [
      queryKeys.plans.list,
      page,
      limit,
      seekers?.companyId,
      seekers?.status,
      debounceSearch,
      id,
    ],
    queryFn: () =>
      PlansService.getPlans({
        page,
        limit,
        companyId: id ? id : seekers?.companyId,
        status: seekers?.status,
        search: debounceSearch,
      }),
  });

  const { data: company } = useQuery({
    queryKey: [queryKeys.customer.company.list],
    queryFn: () => CustomerService.getCompany({ limit: 200, page: 1 }),
  });
  const { data: summary } = useQuery({
    queryKey: [queryKeys.plans.numbers, id],
    queryFn: () => PlansService.getPlansSummary(id ? Number(id) : undefined),
  });

  return (
    <>
      <Box
        width={"99%"}
        margin={"10px 0px"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={summary?.active || "0"}
            subTitle="Planos ativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={summary?.inactive || "0"}
            subTitle="Planos inativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={summary?.expired || "0"}
            subTitle="Planos expirados"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
        <Seprator margin={"10px 0px"} />
        <Column width={"90%"} alignItems="flex-start">
          <Column width={"95%"} alignSelf="center">
            <GenericText font="Bold" size="h4" alignSelf="flex-start">
              Buscadores
            </GenericText>
            <Row justifyContent="space-between" margin={"30px 0px"} wrap="wrap">
              <BoxSelect
                labelContainer="Empresa"
                data={company?.data || []}
                width="90%"
                value={company?.data?.find(
                  (e) => e.id === Number(id) || seekers?.companyId
                )}
                fatherWidth="500px"
                placeholder="Selecione a empresa"
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                onChange={(e: any) =>
                  setSeekers({ ...seekers, companyId: e.id })
                }
              />
              <BoxSelect
                labelContainer="Status"
                value={
                  seekers?.status
                    ? { id: 1, name: "Ativo" }
                    : {
                        id: 2,
                        name: "Inativo",
                      }
                }
                data={[
                  {
                    id: 1,
                    name: "Ativo",
                  },
                  {
                    id: 2,
                    name: "Inativo",
                  },
                ]}
                width="90%"
                fatherWidth="500px"
                placeholder="Selecione o status"
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                onChange={(e: any) => {
                  setSeekers((prev) => ({
                    ...prev,
                    status: e.id === 1 ? true : false,
                  }));
                }}
              />
            </Row>
            <Row justifyContent="space-between" margin={"30px 0px"}></Row>
          </Column>
        </Column>
        <Seprator />
      </Box>
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          data={dataTable?.data || []}
          columns={plansHome()}
          progressPending={!dataTable}
          selectableRows
          pagination
          paginationTotalRows={dataTable?.total}
          onRowClicked={(row: any) =>
            navigate(`/marketing/planos/detalhes/${row.id}`)
          }
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            setPage(newPage);
          }}
          headerComponents={[
            <Input
              label="Busca"
              name="search"
              onChange={(e) => changeStateByInputName(e, setSeekers)}
              width="280px"
              placeholder="Digite a informação para busca"
              margin={"5px 5px"}
            />,
          ]}
        />
      </Box>
      {isLoading && <LoaderGlobal />}
    </>
  );
};
export default Plans;
