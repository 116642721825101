interface ICurrency {
  target: {
    value: any;
  };

  setValue: (value: any) => void;
}

export function currencyMask({ target, setValue }: ICurrency) {
  let value = target.value || "0";

  // Remove tudo que não for número
  value = value.replace(/[^\d]/g, "");

  // Converte para número decimal e garante duas casas decimais
  let numericValue = (parseInt(value, 10) / 100).toFixed(2);

  // Separa a parte inteira e a parte decimal
  let [integerPart, decimalPart] = numericValue.split(".");

  // Formata a parte inteira com pontos como separadores de milhar
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Junta a parte inteira formatada com os centavos
  let formattedResult = `${integerPart},${decimalPart}`;

  // Define o valor no estado com o prefixo "R$"
  if (formattedResult === "0,00") {
    setValue("");
  } else {
    setValue("R$ " + formattedResult);
  }
}
