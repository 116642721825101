/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../../components/Input";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import changeStateByInputName from "../../../../../helpers/state/changeStateByInputName";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import queryKeys from "../../../../../services/utils/queryKeys";

export default function AccessBox({ basic }: { basic: ICustomer }) {
  const [details, setDetails] = useState<Record<string, any>>({});

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.customer.exotic.createAccess],
    mutationFn: CustomerService.createAccess,
  });

  const { data: access, isSuccess: isSuccessGet } = useQuery({
    queryKey: [queryKeys.customer.exotic.listAccess, basic.id],
    queryFn: () => CustomerService.access(String(basic.id)),
    retry: false,
  });

  async function update() {
    mutate({
      companyId: basic.id,
      email: details.email,
      password: details.password,
    });
  }
  function init() {
    if (!access) return;
    setDetails({ email: access.login.email, password: "" });
  }
  useEffect(() => {
    if (!isSuccessGet) {
      return;
    }

    init();
  }, [isSuccessGet]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso!");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Acesso Sistema B2B"
      buttonProps={{
        onClick: update,
        loading: isPending,
        disabled: !details.email || !details.password,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="email"
            value={details.email}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            width={"100%"}
            label="Email"
            type="text"
            margin={"5px 0px"}
            placeholder="Email de acesso ao sistema"
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="password"
            value={details.password || ""}
            width={"100%"}
            label="Senha"
            type="text"
            margin={"5px 0px"}
            placeholder="Senha de acesso ao sistema"
          />
        </>
      )}
    />
  );
}
