import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import Box from "../../components/containers/Box";
import Row from "../../components/containers/Row";
import LoaderGlobal from "../../components/Exotics/loaders/LoaderGlobal";
import { Seprator } from "../../components/Exotics/Seprator";
import Table from "../../components/Table";
import AccessService from "../../services/api/access/AccessService";
import queryKeys from "../../services/utils/queryKeys";
import { accessHome } from "./tables/accessHome";

const Access = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.access.list],
    queryFn: AccessService.getAccess,
  });

  const [filters, setFilters] = useState<Record<string, any>>({
    active: true,
  });
  return (
    <>
      <Box
        width={"99%"}
        margin={"10px 0px"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={""}
            subTitle="Cargos ativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={""}
            subTitle="Cargos inativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />

          <Card
            title={""}
            subTitle="Total de cargos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
      </Box>

      <Seprator />
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          data={data?.data || []}
          selectableRows
          pagination
          paginationTotalRows={data?.count}
          columns={accessHome({
            navigateDetails: (row: any) =>
              navigate(`/cargos/detalhes/${row?.id}`),
          })}
        />
      </Box>
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default Access;
