/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import convertEmptyStringsToNullOrDelete from "../../../../../helpers/object/convertEmptyStringsToNull ";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";
import queryKeys from "../../../../../services/utils/queryKeys";

const MkInformation = ({ basic }: { basic: IBrandDetails }) => {
  const [initialObject, setInitialObject] = useState({});
  const [details, setDetails] = useState<Record<string, any>>({
    establishmentInfo: {},
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.cinema.brand.update],
    mutationFn: CinemaService.updateCinema,
  });

  const disabledButton = useVerifyObjectEqual({
    initialObject,
    actualObject: details,
  });

  const changeStateInformation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDetails((prev) => ({
      ...prev,
      establishmentInfo: {
        ...prev.establishmentInfo,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const init = () => {
    const object = {
      id: basic.id,
      name: basic.name,
      primaryColor: basic.primaryColor || null,
    };
    setInitialObject({ establishmentInfo: object });
    setDetails({ establishmentInfo: object });
  };

  const update = () => {
    const body = {
      establishmentInfo: {
        ...details.establishmentInfo,
      },
    };

    const clearObject = convertEmptyStringsToNullOrDelete(body, true);
    mutate(clearObject as Omit<IBrandDetails, "images">);
    setInitialObject(body);
    setDetails(body);
  };

  useEffect(() => {
    basic && init();
  }, [basic]);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Cinema editado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <SubmitEditsForm
        title="Cor & Marketing"
        buttonProps={{
          disabled: disabledButton,
          onClick: update,
          loading: isPending,
        }}
        renderInsideComponents={() => (
          <>
            <Input
              name="primaryColor"
              width={"100%"}
              value={details.establishmentInfo.primaryColor}
              onChange={(e) => changeStateInformation(e)}
              label="Cor principal"
              placeholder="#ffffff"
              type="color"
              margin={"5px 0px"}
            />
          </>
        )}
      />
    </>
  );
};

export default MkInformation;
