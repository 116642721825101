/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../../../components/Card";
import Box from "../../../../components/containers/Box";
import Column from "../../../../components/containers/Column";
import Row from "../../../../components/containers/Row";
import LoaderGlobal from "../../../../components/Exotics/loaders/LoaderGlobal";
import { Seprator } from "../../../../components/Exotics/Seprator";
import Input from "../../../../components/Input";
import PopUp from "../../../../components/PopUp";
import BoxSelect from "../../../../components/Select/BoxSelect";
import Table from "../../../../components/Table";
import GenericText from "../../../../components/texts/GenericText";
import useDebounce from "../../../../helpers/hooks/useDebounce";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";
import CinemaService from "../../../../services/api/cinema/CinemaService";
import queryKeys from "../../../../services/utils/queryKeys";
import { voucherDetails } from "../../Tables/voucherDetails";

const Vouchers = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  if (!id) {
    navigate("/");
    return <></>;
  }
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [row, setRow] = useState<any>();
  const [reason, setReason] = useState("");
  const [seekers, setSeekers] = useState<Record<string, any>>({
    page: 1,
    limit: 10,
  });
  const debounceSearch = useDebounce(seekers?.search, 500);

  const { data: dataTable, isLoading } = useQuery({
    queryKey: [
      queryKeys.cinema.voucher.list,
      id,
      seekers?.productId,
      seekers?.page,
      seekers?.limit,
      debounceSearch,
    ],
    queryFn: () =>
      CinemaService.getVouchers({
        establishmentId: Number(id),
        limit: seekers?.limit,
        page: seekers?.page,
        productId: seekers?.productId,
        search: debounceSearch,
      }),
  });

  const { data: products } = useQuery({
    queryKey: [queryKeys.cinema.products.list, Number(id)],
    queryFn: () => CinemaService.getProductsCinema(Number(id)),
  });

  const { data: summary } = useQuery({
    queryKey: [queryKeys.cinema.voucher.numbers, id],
    queryFn: () => CinemaService.getSummaryVoucher(Number(id)),
  });

  const { mutate, isSuccess } = useMutation({
    mutationKey: [queryKeys.cinema.voucher.update],
    mutationFn: CinemaService.putVoucher,
  });

  const handleConfirm = () => {
    mutate({ voucherCode: row.voucherCode, motive: reason });
    setReason("");
    setRow("");
    setOpen(!open);
    const updatedData = data.filter((item: any) => item.id !== row.id);
    setData(updatedData);
  };

  useEffect(() => {
    if (dataTable?.data) {
      setData(dataTable?.data as any);
    }
  }, [dataTable]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Ingresso retirado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <Box width={"99%"} margin={"10px 0px"}>
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={summary?.available || 0}
            subTitle="Ingressos disponíveis"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={summary?.user || 0}
            subTitle="Ingressos comprados"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />

          <Card
            title={summary?.withdraw || 0}
            subTitle="Ingressos retirados"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
      </Box>
      <Seprator margin={"10px 0px"} />
      <Column width={"90%"} alignItems="flex-start">
        <Column width={"95%"} alignSelf="center">
          <GenericText font="Bold" size="h4" alignSelf="flex-start">
            Buscadores
          </GenericText>
          <Row justifyContent="space-between" margin={"30px 0px"}>
            <BoxSelect
              value={
                products?.data?.filter(
                  (product) => product.productId === seekers?.productId
                ) || 0
              }
              labelContainer="Produto"
              width="90%"
              fatherWidth="500px"
              data={products?.data || []}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.productId}
              placeholder="Selecione o tipo de Ingresso"
              onChange={(e: any) => {
                setSeekers((prev) => ({ ...prev, productId: e.productId }));
              }}
            />
          </Row>
        </Column>
      </Column>
      <Seprator />
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          data={data || []}
          columns={voucherDetails({
            onClick: (row: any) => {
              setOpen(!open);
              setRow(row);
            },
          })}
          pagination
          paginationTotalRows={dataTable?.total}
          onChangeRowsPerPage={(newLimit, newPage) => {
            setSeekers((prev) => ({
              ...prev,
              limit: newLimit,
              page: newPage,
            }));
          }}
          onChangePage={(newPage) => {
            if (newPage === seekers?.page) {
              return;
            }
            setSeekers((prev) => ({
              ...prev,
              page: newPage,
            }));
          }}
          headerComponents={[
            <Input
              label="Busca"
              name="search"
              onChange={(e) => changeStateByInputName(e, setSeekers)}
              width="280px"
              placeholder="Digite a informação para busca"
              margin={"5px 5px"}
            />,
          ]}
        />
      </Box>
      <PopUp
        title="Remover Ingresso"
        onClose={() => setOpen(!open)}
        open={open}
        buttonProps={{
          disabled: reason.length > 40 ? false : true,
          onClick: handleConfirm,
        }}
        textAreaProps={{
          value: reason,
          onChange: (e) => setReason(e.target.value),
        }}
        subTitle="Motivo"
      />
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default Vouchers;
