import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/buttons/Button";
import Card from "../../components/Card";
import Box from "../../components/containers/Box";
import Column from "../../components/containers/Column";
import Row from "../../components/containers/Row";
import LoaderGlobal from "../../components/Exotics/loaders/LoaderGlobal";
import { Seprator } from "../../components/Exotics/Seprator";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import GenericText from "../../components/texts/GenericText";
import EmployeeService from "../../services/api/employees/EmployeeService";
import {
  ICreateEmployee,
  IEmployee,
} from "../../services/api/employees/interface/IEmployee";
import queryKeys from "../../services/utils/queryKeys";
import { homeEmployee } from "./tables/homeEmployee";

const Employees = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [passWord, setPassWord] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee>(
    {} as IEmployee
  );

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.employee.list, page, limit],
    queryFn: () => EmployeeService.getEmployees({ page, limit }),
  });
  const { mutate } = useMutation({
    mutationKey: [queryKeys.employee.update],
    mutationFn: ({ body, id }: { body: ICreateEmployee; id: number }) =>
      EmployeeService.updateEmployee({ body, id }),
    onSuccess: () => {
      toast.success("Funcionário alterado com sucesso!");
      setOpenModal(false);
      setOpenModalStatus(false);
      setSelectedEmployee({} as IEmployee);
      setPassWord("");
      queryClient.invalidateQueries({
        queryKey: [queryKeys.employee.list, page, limit],
      });
    },
  });

  const handleUpdate = () => {
    const { id, active } = selectedEmployee;
    if (openModalStatus) {
      mutate({
        body: {
          active: !active,
        },
        id,
      });
      return;
    }

    mutate({
      body: {
        password: passWord,
      },
      id,
    });
  };

  return (
    <>
      <Box
        width={"99%"}
        margin={"10px 0px"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={"0"}
            subTitle="Funcionários ativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={"0"}
            subTitle="Funcionários inativos"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={"0"}
            subTitle="Total de funcionários"
            backgroundColor="white"
            buttonProps={{ disabled: true }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
      </Box>
      <Seprator />
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          data={data?.data || []}
          pagination
          paginationTotalRows={data?.count}
          columns={homeEmployee({
            handleChangeModalPassword: (row) => {
              setSelectedEmployee(row);
              setOpenModal(!openModal);
            },
            handleChangeModalStatus: (row) => {
              setSelectedEmployee(row);
              setOpenModalStatus(!openModalStatus);
            },
          })}
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            setPage(newPage);
          }}
        />
      </Box>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(!openModal);
          setSelectedEmployee({} as IEmployee);
          setPassWord("");
        }}
      >
        <Box
          width={"500px"}
          style={{
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <GenericText alignSelf="center" size="h3" margin="0px 0px 12px">
            Alterar senha
          </GenericText>
          <Input
            type="text"
            value={passWord}
            label="Nova Senha"
            placeholder="Digite a nova senha"
            width={"100%"}
            onChange={({ target: { value } }) => setPassWord(value)}
          />
          <Row margin={"30px 0px 0px 0px"} justifyContent="flex-end">
            <Button
              disabled={passWord?.length <= 9}
              onClick={() => handleUpdate()}
              text="Alterar"
              width={"30%"}
              height={"35px"}
            />
          </Row>
        </Box>
      </Modal>
      <Modal
        open={openModalStatus}
        onClose={() => setOpenModalStatus(!openModalStatus)}
      >
        <Box style={{ padding: "20px" }}>
          <Column style={{ gap: "20px" }}>
            <GenericText size="h3" margin="0px 0px 5px" alignSelf="center">{`${
              selectedEmployee.active ? "desativar" : "ativar"
            } funcionário`}</GenericText>
            <GenericText size="h5" margin="0px 0px 5px" alignSelf="flex-start">
              {`Deseja realmente ${
                selectedEmployee.active ? "Desativar" : "Ativar"
              } o funcionário ${selectedEmployee?.name} ?`}
            </GenericText>
            <Row justifyContent="flex-end">
              <Button
                onClick={() => handleUpdate()}
                width={"30%"}
                height={"40px"}
                text={`${selectedEmployee.active ? "Desativar" : "Ativar"}`}
              />
            </Row>
          </Column>
        </Box>
      </Modal>
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default Employees;
