import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Row from "../../../components/containers/Row";
import RegistrationForm from "../../../components/forms/RegistrationForm";
import { CSCContainer } from "../../../components/forms/RegistrationForm/styles";
import useGetCnpjController from "../../../controllers/registration/useGetCnpjController";
import CnpjWithoutMask from "../../../helpers/CnpjWithoutMask";
import cleanObject from "../../../helpers/cleanObject";
import useDebounce from "../../../helpers/hooks/useDebounce";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import useLocation from "../../../hooks/useLocation";
import {
  ISegment,
  IState,
  IStatus,
} from "../../../interfaces/customer/ICustomer";
import CustomerService from "../../../services/api/customer/CustomerService";
import ICities from "../../../services/api/utils/interfaces/ICities";
import queryKeys from "../../../services/utils/queryKeys";

export default function Registration() {
  const [details, setDetails] = useState<Record<string, any>>({});
  const [isCpf, setIsCpf] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [selectState, setSelectState] = useState<IState>({
    id: "" as never,
    name: "Todos",
    regionId: "" as never,
    abbreviation: null,
  });

  const [selectCities, setSelectCites] = useState<ICities>({
    id: "" as never,
    name: "Todas",
    stateId: "" as never,
  });
  const [selectSegment, setSelectSegment] = useState<ISegment>({
    id: 0,
    name: "Segmento",
  });

  const [active, setActive] = useState<IStatus>({
    id: 1,
    name: "Ativo",
  });
  const arrStatus = [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 0,
      name: "Inativo",
    },
  ];

  const [cortesy, setCortesy] = useState({
    id: 1,
    name: "Sim",
    translate: true,
  });
  const arrCortesy = [
    {
      id: 1,
      name: "Sim",
      translate: true,
    },
    {
      id: 2,
      name: "Não",
      translate: false,
    },
  ];

  const { states, isLoadingStates, cities, getCities } = useLocation();
  const {
    data: cnpj,
    setCnpj,
    isSuccess: isSuccessCnpj,
    isLoadingCnpj,
  } = useGetCnpjController();
  const debounceSearchName = useDebounce(details.cnpj, 500);
  const navigate = useNavigate();

  const { data: customers } = useQuery({
    queryKey: [queryKeys.customer.company.list, companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });

  const { data: segments, isLoading: isLoadingSegment } = useQuery({
    queryKey: [queryKeys.customer.segment.list],
    queryFn: () => CustomerService.getSegments(),
  });

  const {
    mutate,
    data,
    isSuccess: isSuccessRegistration,
    isPending,
  } = useMutation({
    mutationKey: [queryKeys.customer.create],
    mutationFn: CustomerService.createCustomer,
  });

  useEffect(() => {
    if (selectState?.id) {
      getCities(selectState?.id.toString());
    }
  }, [selectState?.id]);

  useEffect(() => {
    if (debounceSearchName) {
      setCnpj(CnpjWithoutMask(details?.cnpj));
    }
  }, [debounceSearchName]);
  useEffect(() => {
    if (!!cnpj) {
      setDetails((state) => ({
        ...state,
        corporateName: cnpj?.nome,
        name: cnpj?.fantasia,
      }));
    }
  }, [cnpj]);
  useEffect(() => {
    if (isSuccessRegistration) {
      toast("Empresa criada com sucesso 👌", { type: "success" });
      navigate(`/clientes/detalhes/${data?.id}`);
    }
  }, [isSuccessRegistration]);

  function create() {
    const newObject = {
      cnpj: CnpjWithoutMask(details.cnpj || ""),
      name: details?.name,
      corporateName: details?.corporateName,
      segment: selectSegment?.id,
      active: active?.id,
      state: selectState?.id,
      city: selectCities?.id,
      isCPF: isCpf,
      email: details.email,
      motherCompany: details?.companyId,
      cortesy: cortesy?.translate,
    };
    mutate(cleanObject(newObject));
  }

  return isLoadingCnpj ? (
    <LoaderGlobal />
  ) : (
    <RegistrationForm
      title="Insira os dados principais do cliente"
      buttonProps={{
        disabled:
          !details?.name ||
          !details?.email ||
          !active ||
          !details?.corporateName ||
          !details.cnpj,
        onClick: () => {
          create();
        },
        loading: isPending,
        width: "300px",
      }}
      renderHeaderComponent={() => (
        <Row position="relative" width={"350px"}>
          <Row height={"auto"} position="absolute" margin="0px 0px 40px 260px">
            <Input
              onChange={(e) => setIsCpf(e.target.checked)}
              type="checkbox"
              label="CPF?"
              checked={isCpf}
              labelOrientation="row"
            />
          </Row>
          <Input
            maskType={isCpf ? "cpf" : "cnpj"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name={"cnpj"}
            width={"100%"}
            label={isCpf ? "CPF" : "CNPJ"}
            type="text"
            placeholder={`insira o ${isCpf ? "CPF" : "CNPJ"}`}
            required
            value={details?.cnpj || ""}
          />
        </Row>
      )}
      renderInsideComponents={() => (
        <CSCContainer>
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="name"
            value={details?.name || ""}
            width={"100%"}
            label="Nome"
            type="text"
            required
            placeholder="Nome"
            disabled={!isSuccessCnpj}
          />

          <Select
            data={states || []}
            value={selectState}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => setSelectState(e)}
            label="Estado"
            placeholder="Estado"
            isLoading={isLoadingStates}
            isDisabled={!isSuccessCnpj}
          />
          <Select
            data={arrCortesy || []}
            getOptionLabel={(item: any) => item?.name}
            getOptionValue={(item: any) => item?.id}
            onChange={(e: any) => {
              setCortesy(e);
            }}
            placeholder="Cortesia"
            label="Cortesia"
            value={cortesy}
            isDisabled={!isSuccessCnpj}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="corporateName"
            value={details?.corporateName || ""}
            width={"100%"}
            label="Razão Social"
            type="text"
            required
            placeholder="Razão Social"
            disabled={!isSuccessCnpj}
          />
          <Select
            data={(cities[selectState?.id.toString()] as any) || []}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            value={selectCities}
            onChange={(e: any) => setSelectCites(e)}
            label="Cidade"
            isDisabled={!isSuccessCnpj}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="email"
            value={details.email || ""}
            width={"100%"}
            label="E-mail para contato"
            type="text"
            placeholder="E-mail"
            disabled={!isSuccessCnpj}
            required
          />
          <Select
            data={segments || []}
            value={selectSegment}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => setSelectSegment(e)}
            label="Segmento"
            placeholder="Segmento"
            isLoading={isLoadingSegment}
            isDisabled={!isSuccessCnpj}
          />

          <Select
            data={arrStatus || []}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => setActive(e)}
            label="Status"
            placeholder={"Status"}
            value={active}
            isDisabled={!isSuccessCnpj}
            required
          />
          <Select
            data={customers?.data || []}
            getOptionLabel={(option: any) => option?.name}
            getOptionValue={(option: any) => option?.id}
            onInputChange={(e) => {
              setCompanyName(e);
            }}
            placeholder="Empresa mãe"
            onChange={(e) => {
              setDetails((state) => ({
                ...state,
                companyId: e?.id,
              }));
            }}
            label="Empresa mãe"
            isDisabled={!isSuccessCnpj}
          />
        </CSCContainer>
      )}
    />
  );
}
