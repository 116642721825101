import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import Arrow from "../../../assets/svg/Arrow";
import {
  IPage,
  IPageChild,
} from "../../../services/api/profile/interface/IProfile";
import GenericText from "../../texts/GenericText";
import {
  PlainNavLink,
  SidebarContent,
  SidebarItemWrapper,
  SidebarTitle,
} from "./styles";

interface SidebarItemProps {
  item: IPage | IPageChild;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ item }) => {
  const hasChildren = (item as IPage).childrens !== undefined;
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!item) return;

    const normalizeSlug = (slug: string) => {
      try {
        return new URL(slug).pathname; // Extrai somente o pathname
      } catch {
        return slug; // Retorna o slug como está se não for uma URL completa
      }
    };

    // Verifica se o pathname corresponde ao slug raiz
    const matchesRootSlug = pathname === normalizeSlug(item.slug);

    let matchesChildSlug = false;

    // Verifica se o item possui filhos (childrens)
    if ("childrens" in item && Array.isArray(item.childrens)) {
      matchesChildSlug = item.childrens.some((child) => {
        const normalizedChildSlug = normalizeSlug(child.slug);
        return pathname.startsWith(normalizedChildSlug);
      });
    }

    // Define se o menu deve estar aberto
    setOpen(matchesRootSlug || matchesChildSlug);
  }, [pathname, item]);

  const toggleOpen = () => setOpen((prevOpen) => !prevOpen);

  if (hasChildren) {
    return (
      <SidebarItemWrapper isMyItem={!open}>
        <SidebarTitle onClick={toggleOpen}>
          <span>
            <GenericText color={theme.primary} size="h5" font="Regular">
              {item.title}
            </GenericText>
          </span>
          <Arrow style={open ? { transform: `rotate(90deg)` } : {}} />
        </SidebarTitle>
        <SidebarContent isOpen={open}>
          {(item as IPage).childrens?.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </SidebarContent>
      </SidebarItemWrapper>
    );
  }

  return (
    <PlainNavLink to={item.slug} active={pathname === item.slug}>
      <GenericText color="#a1a1a1" font="Regular" size="h6">
        {item.title}
      </GenericText>
    </PlainNavLink>
  );
};

export default SidebarItem;
