/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useRef, useState } from "react";
import CrookedPolygon from "../../../assets/svg/CrokedPolygon";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { EHActionBox, EHContainer, EHImage, EHStatusButton } from "./styles";
import { useTheme } from "styled-components";
import Column from "../../containers/Column";
import { Seprator } from "../../Exotics/Seprator";
import { IEntityHeaderProps } from "./interfaces/IEntityHeaderProps";
import cpfMask from "../../../helpers/mask/cpfMask";
import { cnpjMask } from "../../../helpers/mask/cnpjMask";
import UserProfile from "../../../assets/svg/UserProfile";
import CompanyProfile from "../../../assets/svg/CompanyProfile";
import Modal from "../../Modal";
import Box from "../../containers/Box";
import Button from "../../buttons/Button";
import { toast } from "react-toastify";

export default function EntityHeader(props: IEntityHeaderProps) {
  const { statusButtonProps = {} } = props;
  const [brand, setBrand] = useState<string | undefined | Blob>(props?.brand);
  const [modal, setModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  function formatArrayNames() {
    if (props.names && !props.names?.length) {
      return " Nenhum comercial atrelado.";
    }
    return `${props.names?.map((el) => " " + el + " ")}`;
  }
  function changeImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files) return;
    if (props.sendChangeImage) {
      const form = new FormData();
      form.append("file", e.target.files[0]);
      setBrand(e.target.files[0]);
      props.sendChangeImage(e.target.files[0]);
      setModal(false);
      toast.success("Imagem alterada com sucesso!");
    }
  }
  useEffect(() => {
    if (props.brand) {
      setBrand(props?.brand);
    }
  }, [props?.brand]);
  return (
    <Column>
      <EHContainer>
        <Column width={"auto"} alignItems="flex-start">
          <GenericText margin="0px 0px 15px 0px" size={"h4"} font="Bold">
            {props?.title}
          </GenericText>
          <Row justifyContent="flex-start" width={"auto"}>
            <EHActionBox
              cursor={props.sendChangeImage ? "pointer" : "default"}
              onClick={() => {
                setModal(true);
              }}
            >
              <EHImage>
                {props.brand && !props.companyImage ? (
                  <img
                    alt="entity-image"
                    style={{ width: "75px", height: "75px" }}
                    src={
                      brand instanceof Blob
                        ? URL.createObjectURL(brand)
                        : brand
                        ? brand
                        : ""
                    }
                  />
                ) : props.companyImage ? (
                  <CompanyProfile />
                ) : (
                  <UserProfile />
                )}
              </EHImage>
              {props.sendChangeImage && (
                <>
                  <CrookedPolygon
                    color={theme.primary}
                    width={15}
                    style={{
                      alignSelf: "flex-end",
                      marginBottom: -5,
                      marginLeft: -5,
                    }}
                  />
                  <input
                    id="entity-input-image"
                    type="file"
                    onChange={changeImage}
                    style={{ display: "none" }}
                    ref={inputRef}
                  />
                </>
              )}
            </EHActionBox>
            <Column width={"auto"} alignItems="flex-start">
              <GenericText
                margin="2px 0px"
                font="Regular"
                size={"h6"}
              >{`ID: ${props?.id}`}</GenericText>
              <GenericText margin="1px 0px" font="Bold" size={"h5"}>
                {props?.name}
              </GenericText>
              {props.cnpj && (
                <GenericText margin="1px 0px" font="Regular" size={"h6"}>
                  {props && props?.cnpj?.length === 11
                    ? `CPF: ${cpfMask(props?.cnpj)}`
                    : `CNPJ: ${cnpjMask(props?.cnpj)}`}
                </GenericText>
              )}
              <GenericText
                margin="1px 0px"
                font="Regular"
                size={"h7"}
              >{`COMERCIAIS:${formatArrayNames()}`}</GenericText>
            </Column>
          </Row>
        </Column>
        <EHStatusButton
          text={
            props.status === "active"
              ? "Ativo"
              : props.status === "inactive"
              ? "Inativo"
              : "Pendente"
          }
          status={props.status}
          {...statusButtonProps}
        />
      </EHContainer>
      <Seprator margin={"20px 0px"} />
      <Modal open={modal} onClose={() => setModal(false)}>
        <Box
          style={{ padding: "10px" }}
          direction="column"
          width={"50vw"}
          height={"500px"}
        >
          {brand && (
            <img
              width={"350px"}
              height={"350px"}
              src={
                brand instanceof Blob
                  ? URL.createObjectURL(brand)
                  : brand
                  ? brand
                  : ""
              }
            />
          )}
          {!brand && (
            <GenericText margin="0px 0px 0px 0px" font="Regular" size={"h7"}>
              Nenhuma imagem selecionada.
            </GenericText>
          )}
          <Button
            margin={"30px 0px 0px 0px"}
            text={!brand ? "Selecionar imagem" : "Alterar"}
            width={"200px"}
            onClick={() => inputRef.current?.click()}
            disabled={!props.sendChangeImage}
          />
        </Box>
      </Modal>
    </Column>
  );
}
