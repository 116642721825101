/* eslint-disable jsx-a11y/alt-text */
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Column from "../../components/containers/Column";
import GenericText from "../../components/texts/GenericText";
import useAuth from "../../hooks/useAuth";

const Home = () => {
  const { profile } = useAuth();

  return (
    <Column>
      <GenericText>Bem vindo, {profile.name}</GenericText>
    </Column>
  );
};

export default Home;
