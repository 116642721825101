/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../../components/Input";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import queryKeys from "../../../../../services/utils/queryKeys";
import { isEqual } from "lodash";
import LoaderGlobal from "../../../../../components/Exotics/loaders/LoaderGlobal";

export default function MediquoCredentials({ basic }: { basic: ICustomer }) {
  const [initialObject, setInitialObject] = useState<Record<any, any>>({});
  const [updateObject, setUpdateObject] = useState<{
    apiKey: string;
    secretKey: string;
  }>({
    apiKey: "",
    secretKey: "",
  });
  const {
    data,
    isSuccess: querySuccess,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.customer.mediquo.details, basic?.id],
    queryFn: () => CustomerService.getMediquoByid(basic?.id),
    enabled: !!basic.id,
    retry: false,
  });
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [queryKeys.customer.mediquo.createOrUpdate],
    mutationFn: CustomerService.createIOrUpdateMediquo,
  });
  useEffect(() => {
    if (querySuccess) {
      let object = { apiKey: data.apiKey, secretKey: data.secretKey };
      setInitialObject(object);
      setUpdateObject(object);
    }
  }, [querySuccess]);

  const update = () => {
    mutate({
      id: basic.id,
      update: updateObject,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setInitialObject(updateObject);
      toast.success("Dados salvos com sucesso!");
    }
  }, [isSuccess]);
  return (
    <SubmitEditsForm
      title="Telemedicina Mediquo"
      buttonProps={{
        disabled:
          isEqual(initialObject, updateObject) ||
          !updateObject.apiKey ||
          !updateObject.secretKey,
        onClick: update,
        loading: isPending,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            width={"100%"}
            label="apiKey"
            value={updateObject.apiKey}
            name="apiKey"
            placeholder="Insira para habilitar integração"
            onChange={(e) =>
              setUpdateObject({ ...updateObject, apiKey: e.target.value })
            }
            type="text"
            margin={"5px 0px"}
          />
          <Input
            width={"100%"}
            label="secretKey"
            value={updateObject.secretKey}
            name="secretKey"
            placeholder="Insira para habilitar integração"
            onChange={(e) =>
              setUpdateObject({ ...updateObject, secretKey: e.target.value })
            }
            type="text"
            margin={"5px 0px"}
          />
          {isLoading && <LoaderGlobal />}
        </>
      )}
    />
  );
}
