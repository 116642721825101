/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import LoaderGlobal from "../../../../../components/Exotics/loaders/LoaderGlobal";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import integrationService from "../../../../../services/api/integration/integrationService";
import {
  IGetIntegration,
  IPatchIntegration,
} from "../../../../../services/api/integration/interface";
import queryKeys from "../../../../../services/utils/queryKeys";
import GenericContainer from "./integration/GenericContainer";
import { mockFieldIntegration } from "./integration/mockFieldIntegration";

const BasicIntegration = ({
  id,
  data,
  basic,
}: {
  id: number;
  data: IGetIntegration;
  basic: ICustomer;
}) => {
  const [integration, setIntegration] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState<Record<string, any>>({});
  const [selectIntegration, setSelectIntegration] = useState("");
  const [isIntegration, setIsIntegration] = useState(false);

  const { data: syncInformation, isSuccess: isSyncSuccess } = useQuery({
    queryKey: [queryKeys.integration.snyc.details, id],
    queryFn: () => integrationService.getSync(id),
    retry: false,
    enabled: !!id,
  });
  const { mutate: createSync, isPending: loadingSync } = useMutation({
    mutationKey: [queryKeys.integration.snyc.create, id],
    mutationFn: integrationService.createOrUpdateSync,
    onSuccess: () => {
      toast.success("Rotinas criadas/alteradas");
    },
    onError: (error) => {
      toast.error("Erro ao adcionar rotinas");
    },
  });
  const { mutate, isPending: loadingPost } = useMutation({
    mutationKey: [queryKeys.integration.create, id],
    mutationFn: integrationService.postIntegration,
    onSuccess: () => {
      toast.success("Integração criada com sucesso");
      setInitialObject(integration);
      setIsIntegration(true);
    },
    onError: (error) => {
      toast.error("Erro ao criar integração");
    },
  });

  const { mutate: update, isPending: loadingPut } = useMutation({
    mutationKey: [queryKeys.integration.update, id],
    mutationFn: (body: IPatchIntegration) =>
      integrationService.patchIntegration(id, body),
    onSuccess: () => {
      toast.success("Integração alterada com sucesso");
      setInitialObject(integration);
      setIsIntegration(true);

      createSync({
        id,
        products: basic.companiesProducts
          .map((product) => (product.active ? product.id : undefined))
          .filter((el) => el !== undefined),
        type: syncInformation?.synchronism.type ? "update" : "create",
      });
    },
    onError: (error) => {
      toast.error("Erro ao alterar integração");
    },
  });
  useEffect(() => {
    if (isSyncSuccess) {
      setIntegration((prev) => ({
        ...prev,
        sync: syncInformation.synchronism.type ? true : false,
      }));

      setInitialObject((prev) => ({
        ...prev,
        sync: syncInformation.synchronism.type ? true : false,
      }));
    }
  }, [isSyncSuccess]);
  const handleConfirm = () => {
    const { type, sync, ...rest } = integration;
    if (isIntegration) {
      const object = {
        integrationType: data?.integration.type,
        integrationData: rest,
      };
      update(object as any);
      return;
    }
    const object = {
      companyId: id,
      integrationType: data?.integration.type || integration.type,
      data: rest,
    };
    mutate(object as any);
  };

  const handleChangeIntegration = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | boolean,
    key: string
  ) => {
    const value = typeof e === "boolean" ? e : e.target?.value || undefined;

    setIntegration((prev) => ({ ...prev, [key]: value }));
  };
  const updateIntegration = (integrationData: Record<string, any>) => {
    setIntegration((el) => ({ ...integrationData, sync: el.sync }));
    setInitialObject((el) => ({ ...integrationData, sync: el.sync }));
  };
  const handleIntegrationUpdate = () => {
    if (data?.integration?.data) {
      updateIntegration(data.integration.data);
      setSelectIntegration(data.integration.type);
      setIsIntegration(true);
    }
  };
  const handleSelectedIntegration = () => {
    const selectedIntegration = mockFieldIntegration.find(
      (item: any) => item.type === selectIntegration
    );

    if (!selectedIntegration) return;

    let newIntegrationData: Record<string, any> = {
      ...selectedIntegration,
      id,
    };

    if (selectIntegration === data?.integration?.type) {
      setIsIntegration(true);
      updateIntegration(data.integration.data);
    } else {
      setIsIntegration(!!data?.integration?.data);
      updateIntegration(newIntegrationData);
    }
  };
  useEffect(() => {
    if (data?.integration?.data && !selectIntegration.length) {
      handleIntegrationUpdate();
    } else if (selectIntegration.length) {
      handleSelectedIntegration();
    }
  }, [data?.integration?.data, selectIntegration, mockFieldIntegration, id]);

  const isLoading = loadingPut || loadingPost;

  const RenderIntegration = useMemo(() => {
    return (
      <>
        <GenericContainer
          loading={isLoading}
          handleChangeIntegration={handleChangeIntegration}
          initialObject={initialObject}
          integration={integration}
          handleConfirm={handleConfirm}
          setSelectIntegration={setSelectIntegration}
          selectIntegration={selectIntegration}
        />
        {(loadingSync || loadingPost || loadingPut) && <LoaderGlobal />}
      </>
    );
  }, [
    data,
    initialObject,
    integration,
    selectIntegration,
    isLoading,
    loadingSync,
    loadingPost,
    loadingPut,
  ]);

  return RenderIntegration;
};

export default BasicIntegration;
